import * as axios from 'axios';
import { getAuthHeader } from './../utils';

// Animal
export const addAnimalType = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/type`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getAnimalType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getAnimalTypeList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        console.log("response  ", response.data)
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteAnimalType = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/type-del`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateAnimalType = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/type`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

// Breed
export const addBreed = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/addBreeds`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getBreedList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getBreedList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteAnimalBreed = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/breed-del`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateAnimalBreed = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/breed`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

// Stud
export const addStudName = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/addStudName`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getStudNameList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getStudNameList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteAnimalStud = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/stud-del`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateAnimalStud = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/stud`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

// Category
export const addCategory = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/category`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCategoryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/category`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateCategory = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/category`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteCategory = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/category-del`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

// Animal Mapping with Breed
export const addAnimalWithBreed = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/addAnimalTypeWithBreed`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

// Animal Mapping with Breed
export const updateAnimalWithBreed = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/updateMapping`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

// Animal Mapping with Breed
export const getAllAnimalTypeWithBreed = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getAnimalTypeWithBreed`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getBreedWithAnimal = (animalTypeId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getBreedViaAnimalType/${animalTypeId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}