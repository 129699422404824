import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import CONSTANT from './../../constant';
import { getVendorPlan, getUserPlan, deleteUserPlan, deleteVendorPlan } from './../../action/PlanAction';
import { onChange } from './../../utils';

class PlanList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            vendorPlanList: undefined,
            userPlanList: undefined,
            page: 1,
            limit: 10,
            sort: 'desc',
            count: 0,
            showLoader: false,
            totalVendorPlan: undefined,
            totalUserPlan: undefined,
            planType: { name: 'planType', value: 'vendor', error: '', isRequired: false },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Plan Management
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { vendorPlanList, userPlanList, planType } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <div className="form-group row">
                                        <div className="radio-inline">
                                            <label className="radio radio-primary mt-3">
                                                <input type="radio" name={planType.name} className="mr-2" value="vendor" checked={planType.value === 'vendor'} onChange={this.onChangePlan} />
                                                <span>Vendor Plans</span>
                                            </label>
                                            <label className="radio radio-primary ml-3">
                                                <input type="radio" name={planType.name} className="mr-2" value="user" checked={planType.value === 'user'} onChange={this.onChangePlan} />
                                                <span>User Plans</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <a href={CONSTANT.url.addPlan} className="btn btn-brand btn-elevate btn-icon-sm">
                                        New Plan <i className="la la-plus ml-3"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        {planType.value === 'vendor' && vendorPlanList &&
                                            this.renderVendorTable()
                                        }
                                        {planType.value === 'user' && userPlanList &&
                                            this.renderUserTable()
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    renderVendorTable = () => {
        const { vendorPlanList } = this.state;

        const rowStyle = (row: any, rowIndex: any) => {
            if (rowIndex % 2 === 0) {
                return {
                    backgroundColor: '#efefef'
                };
            }
            return {
                backgroundColor: '#ffffff'
            };
        }
        const columns = [{
            dataField: 'index',
            text: 'No.',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return (<span>{rowIndex + 1}</span>)
            },
        }, {
            dataField: 'name',
            text: 'Plan Name',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                const url = CONSTANT.url.userListWithPlanId.replace(':id', row.id);
                return (<a href={url}><span className="btn kt-font-brand">{row.name}</span></a>)
            },
        }, {
            dataField: 'features',
            text: 'Features',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.features}`}</span>
            },
            sort: true
        }, {
            dataField: 'terms',
            text: 'T & C',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.terms}`}</span>
            },
        }, {
            dataField: 'price',
            text: 'Cost',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`$${row.price}`}</span>
            },
        }, {
            dataField: 'validity',
            text: 'Validity',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.validity} ${row.subscriptionType}`}</span>
            },
        }, {
            dataField: 'userCount',
            text: 'Total Users',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.userCount ? row.userCount : 0}`}</span>
            },
        }, {
            dataField: 'View',
            text: 'View',
            formatter: this.viewVCellFormatter,
        }, {
            dataField: 'Delete',
            text: 'Delete',
            formatter: this.deleteActionCellFormatterVendor,
        }];
        return (<React.Fragment>
            <BootstrapTable defaultSortDirection="asc" bootstrap4 keyField='index' data={vendorPlanList} columns={columns} />
        </React.Fragment>)
    }

    renderUserTable = () => {
        const { userPlanList } = this.state;

        const rowStyle = (row: any, rowIndex: any) => {
            if (rowIndex % 2 === 0) {
                return {
                    backgroundColor: '#efefef'
                };
            }
            return {
                backgroundColor: '#ffffff'
            };
        }
        const columns = [{
            dataField: 'index',
            text: 'No.',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return (<span>{rowIndex + 1}</span>)
            },
        }, {
            dataField: 'name',
            text: 'Plan Name',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                const url = CONSTANT.url.userListWithPlanId.replace(':id', row.id);
                return (<a href={url}><span className="btn kt-font-brand">{row.name}</span></a>)
            },
        }, {
            dataField: 'features',
            text: 'Features',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.features}`}</span>
            },
            sort: true
        }, {
            dataField: 'terms',
            text: 'T & C',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.terms}`}</span>
            },
        }, {
            dataField: 'price',
            text: 'Cost',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`$${row.price}`}</span>
            },
        }, {
            dataField: 'validity',
            text: 'Validity',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.validity} ${row.subscriptionType}`}</span>
            },
        }, {
            dataField: 'userCount',
            text: 'Total Users',
            sort: true,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.userCount ? row.userCount : 0}`}</span>
            },
        }, {
            dataField: 'View',
            text: 'View',
            formatter: this.viewUCellFormatter,
        }, {
            dataField: 'Delete',
            text: 'Delete',
            formatter: this.deleteActionCellFormatterUser,
        }];
        return (<React.Fragment>
            <BootstrapTable defaultSortDirection="asc" bootstrap4 keyField='index' data={userPlanList} columns={columns} />
        </React.Fragment>)
    }

    deleteActionCellFormatterUser = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
            <span className="btn kt-font-danger" onClick={() => this.deleteUPlan(row)}><i className="fa fa-trash-alt"></i></span>
        )
    }

    deleteActionCellFormatterVendor = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
            <span className="btn kt-font-danger" onClick={() => this.deleteVPlan(row)}><i className="fa fa-trash-alt"></i></span>
        )
    }

    viewVCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        const vendor_url = CONSTANT.url.planVendorEdit.replace(':id', row.id);
        return <a href={vendor_url}><span className="btn kt-font-brand">View Details</span></a>
    }

    viewUCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        const user_url = CONSTANT.url.planUserEdit.replace(':id', row.id);
        return <a href={user_url}><span className="btn kt-font-brand">View Details</span></a>
    }

    private onChangePlan = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);

        if (value === 'vendor') {

        }
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getVendorPlan(page, limit, sort).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({
                    vendorPlanList: res.data.list,
                    totalVendorPlan: res.data.totalVendorPlan
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, vendorPlanList: [], totalVendorPlan: 0, });
        });

        getUserPlan(page, limit, sort).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({
                    userPlanList: res.data.list,
                    totalUserPlan: res.data.totalVendorPlan
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, userPlanList: [], totalUserPlan: 0, });
        });
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }

    deleteVPlan = (detail: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${detail.name}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                deleteVendorPlan({ planId: detail.id }).then((res: any) => {
                    this.setState({ showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Catalogue has been deleted.',
                        'success'
                    )
                    this.loadList();
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your plan is safe :)',
                    'error'
                )
            }
        })
    }

    deleteUPlan = (detail: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${detail.name}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                deleteUserPlan({ planId: detail.id }).then((res: any) => {
                    this.setState({ showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Catalogue has been deleted.',
                        'success'
                    )
                    this.loadList();
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your plan is safe :)',
                    'error'
                )
            }
        })
    }

}

export default PlanList;
