import * as axios from 'axios';
// import CONSTANT from './../constant';
// import { setStorage, getAuthHeader } from './../utils';

export const registerUser = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/users`;
    return axios.post(url, model)
        .then((res: any) => {
            console.log("res api ", res);
            if (res.data.status) {
                // setStorage(CONSTANT.keys.token, res.data.result.token);
                // setStorage(CONSTANT.keys.userDetail, res.data.result);
            }
            return res;
        }).catch((err: any) => {
            console.log(err.response);
            throw err.response;
        });
}