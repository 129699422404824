import * as React from 'react';
import NumberFormat from 'react-number-format';

import { onChange, validateForm } from './../../utils';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? props.id : undefined,
            planName: { name: 'planName', value: detail && detail.name ? detail.name : '', error: '', isRequired: true, options: [] },
            validity: { name: 'validity', value: detail && detail.validity ? detail.validity : '', error: '', isRequired: true },
            subscription: { name: 'subscription', value: detail && detail.subscriptionType ? detail.subscriptionType : '', error: '', isRequired: true, options: [] },
            noOfCatalogue: { name: 'noOfCatalogue', value: detail && detail.noOfCatalogue ? detail.noOfCatalogue : '', error: '', isRequired: true },
            price: { name: 'price', value: detail && detail.price ? detail.price : '', error: '', isRequired: true },
            features: { name: 'features', value: detail && detail.features ? detail.features : '', error: '', isRequired: true },
            terms: { name: 'terms', value: detail && detail.terms ? detail.terms : '', error: '', isRequired: true },
            planType: { name: 'planType', value: detail && detail.isFree ? detail.isFree ? 'free' : '' : 'paid', error: '', isRequired: true },
        }
    }

    public render() {
        const { planName, validity, subscription, noOfCatalogue, price, features, terms, planType } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-10 form-group">
                                <input
                                    type="text"
                                    className={planName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Plan Name"
                                    name={planName.name}
                                    value={planName.value}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="col-lg-2 ml-0 mt-1">
                                <button type="submit" className="btn btn-primary btn-sm btn-block">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <div className="form-group col-lg-12 row">
                            <label className="col-4 col-form-label">Subscription</label>
                            <div className="col-8">
                                <select
                                    className={subscription.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    name={subscription.name}
                                    value={subscription.value}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select subscription</option>
                                    <option value="month">Monthly</option>
                                    <option value="year">Yearly</option>
                                    {
                                        subscription.options.map(function (item: any, ind: any) {
                                            return (
                                                <option key={ind} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group col-lg-12 row">
                            <label className="col-3 col-form-label pl-0">Validity</label>
                            <div className="col-9">
                                <NumberFormat
                                    className={validity.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Enter duration"
                                    name={validity.name}
                                    maxLength={2}
                                    allowNegative={false}
                                    value={validity.value}
                                    allowLeadingZeros={true}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group row col-lg-12 ">
                            <label className="col-4 col-form-label">Plan Type</label>
                            <div className="col-lg-8 form-group">
                                <select
                                    className={planType.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    name={planType.name}
                                    value={planType.value}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select plan type</option>
                                    <option value="free">Free</option>
                                    <option value="paid">Paid</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8">
                        <div className="form-group row">
                            <label className="col-3 col-form-label">Number of Catalogues</label>
                            <div className="col-2">
                                <NumberFormat
                                    className={noOfCatalogue.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder=""
                                    name={noOfCatalogue.name}
                                    maxLength={3}
                                    allowNegative={false}
                                    value={noOfCatalogue.value}
                                    allowLeadingZeros={true}
                                    onChange={this.onChange}
                                />
                            </div>
                            <label className="col-1 col-form-label">Price</label>
                            <div className="col-3">
                                <NumberFormat
                                    className={price.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Price"
                                    name={price.name}
                                    maxLength={10}
                                    allowNegative={false}
                                    value={price.value}
                                    allowLeadingZeros={true}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 form-group">
                        <label>Features</label>
                        <textarea
                            className={features.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            rows={5}
                            placeholder="Write details here"
                            name={features.name}
                            value={features.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-6 form-group">
                        <label>Terms & Conditions</label>
                        <textarea
                            className={terms.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            rows={5}
                            placeholder="Write details here"
                            name={terms.name}
                            value={terms.value}
                            onChange={this.onChange}
                        />
                    </div>
                </div>

            </form>

        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                name: this.state.planName.value,
                validity: this.state.validity.value,
                subscriptionType: this.state.subscription.value,
                noOfCatalogue: this.state.noOfCatalogue.value,
                price: this.state.price.value,
                features: this.state.features.value,
                terms: this.state.terms.value,
                isFree: this.state.planType.value === "free" ? true : false
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;