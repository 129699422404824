import * as React from 'react';
import NumberFormat from 'react-number-format';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { onChange, validateForm } from './../../utils';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? props.id : undefined,
            detail,
            planName: { name: 'planName', value: detail && detail.name ? detail.name : '', error: '', isRequired: true },
            validity: { name: 'validity', value: detail && detail.validity ? detail.validity : '', error: '', isRequired: true },
            subscription: { name: 'subscription', value: detail && detail.subscriptionType ? detail.subscriptionType : '', error: '', isRequired: true, options: [] },
            price: { name: 'price', value: detail && detail.price ? detail.price : '', error: '', isRequired: true },
            features: { name: 'features', value: detail && detail.features ? detail.features : '', error: '', isRequired: true },
            terms: { name: 'terms', value: detail && detail.terms ? detail.terms : '', error: '', isRequired: true },
            planType: { name: 'planType', value: detail && detail.isFree ? detail.isFree ? 'free' : '' : 'paid', error: '', isRequired: true },
            isCheckedAll: false,
            enabledFeatures: [
                { id: 1, value: "share", name: "Share", isChecked: false },
                { id: 2, value: "annotate", name: "Annotate", isChecked: false },
                { id: 3, value: "download", name: "Download", isChecked: false },
                { id: 4, value: "other", name: "Other", isChecked: false },
            ]
        }
    }

    componentDidMount() {
        const { detail, enabledFeatures } = this.state;
        if (detail) {
            const filterRes = enabledFeatures.map((i: any) => {
                for (let k = 0; k < detail.enabledFeature.length; k++) {
                    if (i.id === Number(detail.enabledFeature[k].id)) {
                        i.isChecked = true;
                    }
                }
                return i;
            });
            this.setState({
                enabledFeatures: filterRes,
            })
        }
    }

    public render() {
        const { planName, validity, subscription, price, features, terms, isCheckedAll, planType } = this.state;
        return (
            <React.Fragment >
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-10 form-group">
                                    <input
                                        type="text"
                                        className={planName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Plan Name"
                                        name={planName.name}
                                        value={planName.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-lg-2 ml-0 mt-1">
                                    <button type="submit" className="btn btn-primary btn-sm btn-block">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group col-lg-12 row">
                                <label className="col-4 col-form-label">Subscription</label>
                                <div className="col-8">
                                    <select
                                        className={subscription.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        name={subscription.name}
                                        value={subscription.value}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select subscription</option>
                                        <option value="month">Monthly</option>
                                        <option value="year">Yearly</option>
                                        {
                                            subscription.options.map(function (item: any, ind: any) {
                                                return (
                                                    <option key={ind} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group col-lg-12 row">
                                <label className="col-3 col-form-label pl-0">Validity</label>
                                <div className="col-9">
                                    <NumberFormat
                                        className={validity.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter duration"
                                        name={validity.name}
                                        maxLength={2}
                                        allowNegative={false}
                                        value={validity.value}
                                        allowLeadingZeros={true}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group row col-lg-12 ">
                                <label className="col-4 col-form-label">Plan Type</label>
                                <div className="col-lg-8 form-group">
                                    <select
                                        className={planType.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        name={planType.name}
                                        value={planType.value}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select plan type</option>
                                        <option value="free">Free</option>
                                        <option value="paid">Paid</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-2">
                            <div className="form-group row">
                                <label className="col-6 col-form-label">Price</label>
                                <div className="col-6">
                                    <NumberFormat
                                        className={price.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Price"
                                        name={price.name}
                                        maxLength={5}
                                        allowNegative={false}
                                        value={price.value}
                                        allowLeadingZeros={true}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 pr-2">
                            <div className="form-group row">
                                <label className="col-2 col-form-label">Enable Features</label>
                                <div className="col-10 col-form-label">
                                    <div className="checkbox-inline">
                                        <label className="checkbox pl-5">
                                            <input type="checkbox" name="allCheck" className="mr-2" value="all" checked={isCheckedAll} onChange={this.onChangeAll} />
                                            <span>All</span>
                                        </label>
                                        {
                                            this.state.enabledFeatures.map((item: any, index: any) => {
                                                return (
                                                    <label className="checkbox pl-5" key={index}>
                                                        <input type="checkbox" name={item.name} className="mr-2" value={item.id} checked={item.isChecked} onChange={this.onChangeFeature} />
                                                        <span>{item.name}</span>
                                                    </label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 form-group">
                            <label>Features</label>
                            <textarea
                                className={features.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                rows={5}
                                placeholder="Write details here"
                                name={features.name}
                                value={features.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-6 form-group">
                            <label>Terms & Conditions</label>
                            <textarea
                                className={terms.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                rows={5}
                                placeholder="Write details here"
                                name={terms.name}
                                value={terms.value}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>

                </form>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment >
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    private onChangeAll = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;

        const { enabledFeatures } = this.state;

        const filterRes = enabledFeatures.map((i: any) => {
            i.isChecked = checked;
            return i;
        });
        this.setState({ enabledFeatures: filterRes, isCheckedAll: checked });
    }

    onChangeFeature = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;

        const { enabledFeatures } = this.state;
        const filterRes = enabledFeatures.map((i: any) => {
            if (Number(value) === i.id) {
                i.isChecked = checked;
            }
            return i;
        });
        this.setState({ enabledFeatures: filterRes, isCheckedAll: false }, () => {
            let totalLength = enabledFeatures.length;
            let checkedLength = enabledFeatures.filter((i: any) => i.isChecked).length;
            if (totalLength === checkedLength) {
                this.setState({ isCheckedAll: true });
            }
        });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { planName, validity, subscription, price, enabledFeatures, features, terms, planType } = this.state;
            const filertFeature = enabledFeatures.filter((i: any) => i.isChecked).map((i: any) => {
                let obj = { name: i.name, id: i.id };
                return obj;
            });
            if (filertFeature.length == 0) return toast.warning("Please select feature");
            const model: any = {
                name: planName.value,
                validity: validity.value,
                subscriptionType: subscription.value,
                price: price.value,
                enabledFeature: filertFeature,
                features: features.value,
                terms: terms.value,
                isFree: planType.value === "free" ? true : false
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;