import * as React from 'react';

import Header from './../component/common/Header';
import Sidebar from './../component/common/Sidebar';
import Footer from './../component/common/Footer';
import CONSTANT from './../constant';
import { getDashboardStats, getCategoryWithCatalogueStats } from './../action/MasterAction';

class EmployeeList extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: undefined,
            category: undefined,
            totalCatagory: undefined,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Dashboard
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { data, category, totalCatagory } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="row">

                    <div className="col-md-8">

                        <div className="row text-center">
                            <div className="col-md-6">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body text-center">

                                                <div className="kt-widget__stats text-center">

                                                    <div className="kt-widget__item col-lg-12 text-center mb-4">
                                                        <div className="kt-widget__label mt-4">
                                                            <h1 className="kt-font-bold">{data ? data.vendorCount : '-'}</h1>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <h5 className="">Total no. of Vendor Accounts</h5>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <a href={CONSTANT.url.vendor} className="kt-widget__value kt-font-brand">Vendor Management</a>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body ">

                                                <div className="kt-widget__stats text-center">

                                                    <div className="kt-widget__item col-lg-12">
                                                        <div className="kt-widget__label mt-4">
                                                            <h1 className="kt-font-bold">{data ? data.userCount : '-'}</h1>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <h5 className="">Total no. of End User Accounts</h5>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <a href={CONSTANT.url.user} className="kt-widget__value kt-font-brand">End User Management</a>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4" />

                </div>
                <div className="row">

                    <div className="col-md-8">

                        <div className="row text-center">
                            <div className="col-md-6">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body text-center">

                                                <div className="kt-widget__stats text-center">

                                                    <div className="kt-widget__item col-lg-12 text-center pt-4">
                                                        {category && category.map((i: any) => {
                                                            return (
                                                                <div className="row text-center">
                                                                    <label className="col-6 col-form-label">{i.categoryName}</label>
                                                                    <label className="col-6 col-form-label">{i.count}</label>
                                                                </div>
                                                            )
                                                        })}
                                                        {category && category.length > 0 &&
                                                            <div className="row text-center mt-2">
                                                                <label className="col-6 font-weight-bold">Total</label>
                                                                <label className="col-6 font-weight-bold">{totalCatagory}</label>
                                                            </div>
                                                        }

                                                        <div className="kt-widget__label mt-4">
                                                            <a href={CONSTANT.url.catalogueList} className="kt-widget__value kt-font-brand">Catalogue Management</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body text-center">

                                                <div className="kt-widget__stats pt-4">

                                                    <div className="kt-widget__item col-lg-12 ">
                                                        <div className="kt-widget__label">
                                                            <h1 className="kt-font-bold">{data ? data.updatesCount : '-'}</h1>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <h5 className="">Total no. of Updates</h5>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" />
                </div>
            </div>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        getDashboardStats().then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({ data: res.data });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });

        getCategoryWithCatalogueStats().then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                let totalCatagory = res.data.reduce(function (total: any, currentValue: any) {
                    return total + parseFloat(currentValue.count);
                }, 0);
                this.setState({ category: res.data, totalCatagory });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default EmployeeList;