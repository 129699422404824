import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import { getCatalogueDetail, approveCatalogue } from '../../action/CatalogueAction';
const fileImg = require("./../../assets/images/file.png")

class AddVendorPlan extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            showLoader: false,
            detail: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }

                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Catalogue
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {`${detail && detail.title ? detail.title : '-'}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Catalogue Title
                                    </h3>
                                </div>

                                <div className="kt-portlet__head-toolbar">
                                    {detail && detail.isApproved === false &&
                                        <button className="btn btn-primary font-weight-bolder font-size-sm mr-3" onClick={this.onSubmit}>Approve</button>
                                    }
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="card col-6">
                                                <div className="example-preview" id="kt_blockui_content">
                                                    <img src={`${detail && detail.thumbnailImage ? detail.thumbnailImage : ''}`} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="card col-6 align-items-center ">
                                                <div className="h-100 row align-items-center">
                                                    <a href={detail && detail.cataloguePdf} target="_blank">
                                                        <figure>
                                                            <img src={fileImg} width="80px" height="80px" className="ml-3" />
                                                            <figcaption className="mt-2">View Catalogue</figcaption>
                                                        </figure>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 well">
                                        <div className="row pl-4">
                                            <label className="col-4 col-form-label font-weight-bold">Trading Name</label>
                                            <label className="col-8 col-form-label">{`${detail && detail.tradingName ? detail.tradingName : '-'}`}</label>
                                        </div>
                                        <div className="row pl-4">
                                            <label className="col-4 col-form-label font-weight-bold">Email</label>
                                            <label className="col-8 col-form-label">{`${detail && detail.email ? detail.email : '-'}`}</label>
                                        </div>
                                        <div className="row pl-4">
                                            <label className="col-4 col-form-label font-weight-bold">Phone</label>
                                            <label className="col-8 col-form-label">{`${detail && detail.phone ? detail.phone : '-'}`}</label>
                                        </div>
                                        <div className="row pl-4">
                                            <label className="col-4 col-form-label font-weight-bold">Website</label>
                                            <label className="col-8 col-form-label">{`${detail && detail.website ? detail.website : '-'}`}</label>
                                        </div>
                                        <div className="row pl-4">
                                            <label className="col-4 col-form-label font-weight-bold">Plan Type</label>
                                            <label className="col-8 col-form-label">{`${detail && detail.planType ? detail.planType : '-'}`}</label>
                                        </div>
                                        <div className="row pl-4">
                                            <label className="col-4 col-form-label font-weight-bold">Date Added</label>
                                            <label className="col-8 col-form-label">{`${detail && detail.createdOn ? `${moment(detail.createdOn).format("YYYY-MM-DD")}` : '-'}`}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Stud Details
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row p-2">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Animal Type</th>
                                                <th>Stud Name</th>
                                                <th>Breed</th>
                                                <th>Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                detail !== undefined && detail.studDetail.map((item: any, addIndex: number) => {
                                                    const url = CONSTANT.url.catalogueDetail.replace(':id', item.id);
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{item.animalType}</td>
                                                                <td>{item.studName}</td>
                                                                <td>{item.breed}</td>
                                                                <td>{item.number}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Sale Details
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <label className="col-4 col-form-label font-weight-bold">Sale From</label>
                                    <label className="col-8 col-form-label">{`${detail && detail.saleFromDate}`}</label>
                                </div>
                                <div className="row">
                                    <label className="col-4 col-form-label font-weight-bold">Sale To</label>
                                    <label className="col-8 col-form-label">{`${detail && detail.saleToDate}`}</label>
                                </div>
                                <div className="row">
                                    <label className="col-4 col-form-label font-weight-bold">Sale Type</label>
                                    <label className="col-8 col-form-label">{`${detail && detail.saleType ? detail.saleType : '-'}`}</label>
                                </div>
                                <div className="row">
                                    <label className="col-4 col-form-label font-weight-bold">Sale Location</label>
                                    <label className="col-8 col-form-label">{`${detail && detail.saleLocation}`}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadList = () => {
        const { id } = this.state;
        this.setState({ showLoader: true });
        getCatalogueDetail(id).then((res: any) => {
            if (res.status === 200) {
                this.setState({ detail: res.data, showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onSubmit = () => {
        const { id, detail } = this.state;
        const model = {
            id: detail.catalogueId,
            isApproved: true,
            categoryId: detail.categoryId
        }
        this.setState({ showLoader: true });
        approveCatalogue(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Catalogue successfully approved!");
                window.location.href = CONSTANT.url.catalogueList;
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default AddVendorPlan;
