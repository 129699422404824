import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from "firebase/app";

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import { addSplash, getSplash, setMobileSplash, setTabSplash, deleteSplash } from '../../action/MasterAction';

class Splash extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            showLoader: false,
            mobileSplashList: undefined,
            tabSplashList: undefined,
            firstName: { name: 'firstName', value: '', error: '', isRequired: true },
            lastName: { name: 'lastName', value: '', error: '', isRequired: true },
            email: { name: 'email', value: '', error: '', isRequired: false },
            phone: { name: 'phone', value: '', error: '', isRequired: false },
            imageUrl: undefined,
            fileName: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Edit Splash
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { mobileSplashList, tabSplashList } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="col-md-12">
                    <div className="kt-portlet">

                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Mobile Splash
                                </h3>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="edit">Upoad Splash<input className="ml-2" type="file" accept="image/*" onChange={this.onMobileSplashAdd} /></div>
                            </div>
                        </div>

                        <div className="kt-portlet__body">
                            <div className="row">
                                {mobileSplashList && mobileSplashList.map((item: any) => {
                                    return (
                                        <div className="mt-1 p-2">
                                            <div className="mobile-splash">
                                                <div className="tumbnail">
                                                    <img src={item.imageUrl} alt="avatar" height="300" width="240" />
                                                    <div className="edit"><button type="button" disabled={item.isSet} className="btn btn-sm btn-primary pull-right" onClick={() => this.setMobileSplash(item.id)}>Set</button></div>
                                                    <div className="edit-del"><button disabled={item.isSet} className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteSplash(item)}><i className="fa fa-trash-alt"></i></button></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div >

                <div className="col-md-12">
                    <div className="kt-portlet">

                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Tab Splash
                                </h3>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="edit">Upoad Splash<input className="ml-2" type="file" accept="image/*" onChange={this.onTabSplashAdd} /></div>
                            </div>
                        </div>

                        <div className="kt-portlet__body">
                            <div className="row">
                                {tabSplashList && tabSplashList.map((item: any) => {
                                    return (
                                        <div className="mt-1 p-2">
                                            <div className="tab-splash">
                                                <div className="tumbnail">
                                                    <img src={item.imageUrl} alt="avatar" height="200" width="300" />
                                                    <div className="edit"><button type="button" disabled={item.isSet} className="btn btn-sm btn-primary pull-right" onClick={() => this.setTabSplash(item.id)}>Set</button></div>
                                                    <div className="edit-del"><button className="btn btn-sm btn-danger pull-right ml-3" disabled={item.isSet} onClick={() => this.onDeleteSplash(item)}><i className="fa fa-trash-alt"></i></button></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>

            </div >
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        getSplash().then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                const filterMobileSplash = res.data.filter((i: any) => i.type === 'mobile');
                const filterTabSplash = res.data.filter((i: any) => i.type === 'tab');
                this.setState({ mobileSplashList: filterMobileSplash, tabSplashList: filterTabSplash });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onMobileSplashAdd = (e: any) => {
        const f = e.target.files;
        var file = f[0];
        if (file) {
            this.handleUploadMobileSplash(file);
        }
        e.target.value = '';
    }

    onTabSplashAdd = (e: any) => {
        const f = e.target.files;
        var file = f[0];
        if (file) {
            this.handleUploadTabSplash(file);
        }
        e.target.value = '';
    }

    handleUploadMobileSplash = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.splash}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.splash)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ imageUrl: url, fileName }, () => {
                            this.uploadMobileSplash();
                        });
                    });
            }
        );
    };

    handleUploadTabSplash = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.splash}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.splash)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ imageUrl: url, fileName }, () => {
                            this.uploadTabSplash();
                        });
                    });
            }
        );
    };

    uploadMobileSplash = () => {
        const { id, imageUrl, fileName } = this.state;
        let model: any = {
            imageUrl,
            fileName,
            type: 'mobile'
        };
        if (id) {
            model.id = id;
        }
        addSplash(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Splash uploaded.");
                this.loadList();
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    uploadTabSplash = () => {
        const { id, imageUrl, fileName } = this.state;
        let model: any = {
            imageUrl,
            fileName,
            type: 'tab'
        };
        if (id) {
            model.id = id;
        }
        addSplash(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Splash uploaded");
                this.loadList();
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onSubmit = () => {
        const { id } = this.state;
        this.setState({ showLoader: true });
        const model = {
            uid: id,
            firstName: this.state.firstName.value,
            lastName: this.state.lastName.value,
            displayName: `${this.state.firstName.value} ${this.state.lastName.value}`,
            phone: this.state.phone.value
        }
    }

    setMobileSplash(id: any) {
        let model: any = {
            id,
        };
        this.setState({ showLoader: true });
        setMobileSplash(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Splash updated.");
                this.loadList();
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    setTabSplash(id: any) {
        let model: any = {
            id,
        };
        this.setState({ showLoader: true });
        setTabSplash(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Splash updated.");
                this.loadList();
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onDeleteSplash(item: any) {
        let model: any = {
            id: item.id,
        };
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.splash)
            .child(item.fileName)
            .delete()
            .then(async (url) => {
                this.setState({ showLoader: false });
                await deleteSplash(model);
                toast.success("Splash deleted.");
                this.loadList();
            });
    }

}

export default Splash;