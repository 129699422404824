import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import { addPrivacyPolicy, getPrivacyPolicy } from '../../action/ContentAction';
import { onChange } from './../../utils';
import firebase from 'firebase/app';
import constant from '../../constant';

class MyUploadAdapter extends React.Component<any, any>{
    constructor(loader: any) {
        super(loader)
        this.state = loader ;
    }
     fileDirectory = `${constant.fileDirectory.media_uploads}`;
    // Starts the upload process.
    upload() {
      return this.state.file.then(
        (file: any) =>
          new Promise((resolve, reject) => {
              let storage = firebase.storage().ref(`${this.fileDirectory}/${file.name}`);
            let uploadTask = storage
              .put(file);
              uploadTask.on(
                  firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                () => {
                    uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function(downloadURL) {
                      resolve({
                        default: downloadURL
                      });
                    });
                    }
              );
              
          })
          
        );
    }
}

class PrivacyPolicy extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            id: undefined,
            body: { name: 'body', value: '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Content management
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                Privacy Policy
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { body } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Privacy Policy
                                </h3>
                            </div>
                        </div>

                        <div className="kt-portlet__body">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <CKEditor
                                        editor={ClassicEditor}
                                        onInit={(editor: any) => {
                                            editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
                                                return new MyUploadAdapter(loader);
                                              };
                                        }}
                                        data={body.value}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            onChange(this, body.name, data);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4"></div>
                                <div className="col-md-4"></div>
                                <div className="col-md-2"></div>
                                <div className="col-md-2 pr-4">
                                    <button type="button" className="btn btn-sm btn-primary btn-block" onClick={this.onPublish}>Publish</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onSaveDraft = (model: any) => {
        
    }

    loadList = () => {
        this.setState({ showLoader: true });
        getPrivacyPolicy().then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({
                    body: { ...this.state.body, value: res.data.content },
                    id: res.data.id,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onPublish = () => {
        this.setState({ showLoader: true });
        let reqObj: any = {
            content: this.state.body.value
        }
        if (this.state.id) {
            reqObj.id = this.state.id
        }
        addPrivacyPolicy(reqObj).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 201) {
                toast.success("Success");
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default PrivacyPolicy;