import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import AddCatalogueDetail from '../../component/catalogue-management/Add';
import { getCatalogueDetail, updateCatalogueDetail } from '../../action/CatalogueAction';

class EditCatalogue extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            catalogueId: props.match.params.id,
            detail: undefined,
            showLoader: false
        }
    }

    componentDidMount() {
        this.setState({ showLoader: true });
        getCatalogueDetail(this.state.catalogueId).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({ detail: res.data });
            } else {
                this.setState({ detail: {} });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }

                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            My Catalogue
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { catalogueId, detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {detail && Object.keys(detail).length > 0 &&
                                    < AddCatalogueDetail
                                        onSubmit={this.onSubmit}
                                        id={catalogueId}
                                        detail={detail}
                                    />
                                }
                                {detail && Object.keys(detail).length === 0 &&
                                    <div className="col-lg-12 text-center">
                                        <span className="text-center">No Catalogue Found</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }


    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateCatalogueDetail(model, model.id).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Catalogue successfully updated!");
                window.location.href = CONSTANT.url.catalogueList;
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default EditCatalogue;