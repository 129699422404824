export default {
    url: {
        login: '/',
        register: '/register',
        forgotPassword: '/forgot-password',
        changePassword: '/change-password',
        dashboard: '/dashboard',
        logout: '/logout',
        profile: '/profile',
        profileEdit: '/profile/:id',

        vendor: '/vendor',
        vendorDetail: '/vendor/detail/:id',

        user: '/user',
        userDetail: '/user/detail/:id',

        planList: '/plan-management/list',
        vendorsListWithPlanId: '/plan-management/vendor/list/:id',
        userListWithPlanId: '/plan-management/user/list/:id',
        addPlan: '/plan-management/add',
        planVendorEdit: '/plan-management/edit/vendor/:id',
        planUserEdit: '/plan-management/edit/user/:id',

        couponList: '/coupon-management/list',
        couponVendorEdit: '/coupon-management/edit/vendor/:id',
        couponUserEdit: '/coupon-management/edit/user/:id',
        addCoupon: '/coupon-management/add',

        catalogueList: '/catalogue-management/list',
        addCatalogue: '/catalogue-management/add',
        catalogueDetail: '/catalogue-management/detail/:id',
        catalogueApproval: '/catalogue-management/approval/:id',
        catalogueUpdates: '/catalogue-management/updates/:id',

        aboutUs: '/content-management/about-us',
        support: '/content-management/support',
        termCondition: '/content-management/term-condition',
        privacyPolicy: '/content-management/privacy-policy',
        faq: '/content-management/faq/add',
        editFaq: '/content-management/faq/detail/:id',
        categories: '/content-management/category',
        splash: '/content-management/splash',

        notifications: '/notifications',
        privacy: '/privacy-policy'
    },
    keys: {
        token: 'tk.studbook',
        userDetail: 'ud.studbook',
        remember: 'ld.studbook',
    },
    fileDirectory: {
        catalogues: 'catalogues',
        catalogueThumbnail: 'catalogue-thumbnail',
        category: 'category',
        profilePicture: 'profile_picture',
        splash: 'splash',
        media_uploads: 'media_uploads'
    }
}