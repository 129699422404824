import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import AddVendorPlanComp from './../../component/plan-management/AddVendorPlan';
import AddUserPlanComp from './../../component/plan-management/AddUserPlan';
import { createVendorPlan, createUserPlan } from '../../action/PlanAction';
import { onChange } from './../../utils';

class AddVendorPlan extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            planName: { name: 'planName', value: '', error: '', isRequired: true, options: [] },
            validityFrom: { name: 'validityFrom', value: '', error: '', isRequired: true },
            validityTo: { name: 'validityTo', value: '', error: '', isRequired: true },
            noOfCatalogue: { name: 'noOfCatalogue', value: '', error: '', isRequired: true },
            price: { name: 'price', value: '', error: '', isRequired: true },
            percentage: { name: 'percentage', value: '', error: '', isRequired: true },
            features: { name: 'features', value: '', error: '', isRequired: true },
            terms: { name: 'terms', value: '', error: '', isRequired: true },
            planType: { name: 'planType', value: 'vendor', error: '', isRequired: false },
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Plan Management
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                New Plan
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { planType } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">

                            <div className="kt-portlet__body">
                                <div className="form-group row">
                                    <div className="col-3 col-form-label">
                                        <div className="radio-inline">
                                            <label className="radio radio-primary">
                                                <input type="radio" name={planType.name} className="mr-2" value="vendor" checked={planType.value === 'vendor'} onChange={this.onChangePlan} />
                                                <span>Vendor Plans</span>
                                            </label>
                                            <label className="radio radio-primary ml-5">
                                                <input type="radio" name={planType.name} className="mr-2" value="user" checked={planType.value === 'user'} onChange={this.onChangePlan} />
                                                <span>User Plans</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {planType.value === 'vendor' &&
                                    <AddVendorPlanComp
                                        onSubmit={this.onVendorSubmit}
                                    />
                                }

                                {planType.value === 'user' &&
                                    <AddUserPlanComp
                                        onSubmit={this.onUserSubmit}
                                    />
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    private onChangePlan = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onVendorSubmit = (model: any) => {
        this.setState({ showLoader: true });
        createVendorPlan(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 201) {
                toast.success("Plan Successfully added!");
                this.props.history.push(CONSTANT.url.planList);
            }
            if (res.status === 400) {
                toast.error(res.message);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onUserSubmit = (model: any) => {
        this.setState({ showLoader: true });
        createUserPlan(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 201) {
                toast.success("Plan Successfully added!");
                this.props.history.push(CONSTANT.url.planList);
            }
            if (res.status === 400) {
                toast.error(res.message);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default AddVendorPlan;
