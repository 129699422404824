import * as React from 'react';
import NumberFormat from 'react-number-format';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import { onChange, validateForm } from './../../utils';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        const diff = detail && detail.validityTo ? moment(detail.validityTo).diff(detail.validityFrom, 'M') : "";
        this.state = {
            id: detail ? props.id : undefined,
            detail,
            name: { name: 'name', value: detail && detail.title ? detail.title : '', error: '', isRequired: true, options: [] },
            code: { name: 'code', value: detail && detail.code ? detail.code : '', error: '', isRequired: true },
            validityMonth: { name: 'validityMonth', value: detail && diff ? diff : '', error: '', isRequired: detail && detail.code ? false : true },
            isFixedDiscount: { name: 'isFixedDiscount', value: detail && detail.isFixedDiscount ? `${detail.isFixedDiscount ? 'percentage' : 'fixed'}` : 'percentage', error: '', isRequired: false },
            amount: { name: 'amount', value: detail && detail.amount ? detail.amount : '', error: '', isRequired: false },
            percentageCount: { name: 'percentageCount', value: detail && detail.percentage ? detail.percentage : '', error: '', isRequired: false },
            features: { name: 'features', value: detail && detail.features ? detail.features : '', error: '', isRequired: true },
            terms: { name: 'terms', value: detail && detail.terms ? detail.terms : '', error: '', isRequired: true },
        }
    }

    public render() {
        const { detail, name, code, validityMonth, amount, percentageCount, features, terms, isFixedDiscount } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-10 form-group">
                                    <input
                                        type="text"
                                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Coupon Title"
                                        name={name.name}
                                        value={name.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-lg-2 ml-0 mt-1">
                                    <button type="submit" className="btn btn-primary btn-sm btn-block">Upload</button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-4 form-group">
                            <input
                                type="text"
                                className={code.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Coupon Code"
                                name={code.name}
                                value={code.value}
                                onChange={this.onChange}
                                disabled={detail && detail.code ? true : false}
                            />
                        </div>
                        <div className="col-lg-4 form-group row">
                            <label className="col-4 col-form-label">Validity in Months</label>
                            <div className="col-8">
                                <NumberFormat
                                    disabled = {validityMonth.value ? true : false }
                                    className={validityMonth.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Enter duration"
                                    name={validityMonth.name}
                                    maxLength={2}
                                    allowNegative={false}
                                    value={validityMonth.value}
                                    allowLeadingZeros={true}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group col-lg-12 row">
                                <label className="col-2 col-form-label">Discount Option</label>
                                <div className="col-5 form-group row">
                                    <div className="col-6 radio-inline">
                                        <label className="radio radio-primary mt-3">
                                            <input type="radio" name={isFixedDiscount.name} className="mr-2" value="percentage" checked={isFixedDiscount.value === 'percentage'} onChange={this.onChangeDiscount} />
                                            <span>Percentage</span>
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            className={percentageCount.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            placeholder=""
                                            name={percentageCount.name}
                                            value={percentageCount.value}
                                            onChange={this.onChange}
                                            disabled={isFixedDiscount.value != 'percentage'}
                                        />
                                    </div>
                                </div>

                                <div className="col-5 form-group row">
                                    <div className="col-6 radio-inline">
                                        <label className="radio radio-primary mt-3">
                                            <input type="radio" name={isFixedDiscount.name} className="mr-2" value="fixed" checked={isFixedDiscount.value === 'fixed'} onChange={this.onChangeDiscount} />
                                            <span>Fixed</span>
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            className={amount.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            placeholder=""
                                            name={amount.name}
                                            value={amount.value}
                                            onChange={this.onChange}
                                            disabled={isFixedDiscount.value != 'fixed'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 form-group">
                            <label>Features</label>
                            <textarea
                                className={features.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                rows={5}
                                placeholder="Write details here"
                                name={features.name}
                                value={features.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-6 form-group">
                            <label>Terms & Conditions</label>
                            <textarea
                                className={terms.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                rows={5}
                                placeholder="Write details here"
                                name={terms.name}
                                value={terms.value}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>

                </form>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    private onChangeDiscount = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "isFixedDiscount") {
            if (value === "percentage") {
                this.setState({ amount: { ...this.state.amount, value: "" } });
            }
            if (value === "fixed") {
                this.setState({ percentageCount: { ...this.state.percentageCount, value: "" } });
            }
        }

        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { id, detail, name, code, validityFrom, validityTo, validityMonth, amount, percentageCount, features, terms, isFixedDiscount } = this.state;
            if (!percentageCount.value && isFixedDiscount.value === "percentage") {
                toast.error("Enter percentage!");
                return
            }
            if (!amount.value && isFixedDiscount.value === "fixed") {
                toast.error("Enter amount!");
                return
            }
            let model: any = {
                title: name.value,
                validityMonth: validityMonth.value,
                code: code.value,
                amount: amount.value,
                percentage: percentageCount.value,
                features: features.value,
                terms: terms.value,
                isFixedDiscount: isFixedDiscount.value === "fixed" ? true : false
            };
            if (id) {
                model.id = id;
                model.type = detail.type;
                delete model.code;
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;