import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const createVendorPlan = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const createUserPlan = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getVendorPlan = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getUserPlan = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getVendorPlanDetail = (planId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor/detail/${planId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateUserPlanDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user/update`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateVendorPlanDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor/update`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getUserPlanDetail = (planId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user/detail/${planId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getVendorWithPlanId = (planId: any, page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor/list?id=${planId}&p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}



export const getAllSubscribedPlanUsers = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user-subscribed/list?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getUserWithPlanId = (planId: any, page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user/list?id=${planId}&p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteUserPlan = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user/delete`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteVendorPlan = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor/delete`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}