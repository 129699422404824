import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const addAboutUs = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/about-us`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getAboutUs = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/about-us`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const addSupport = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/support`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getSupport = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/support`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const addTermCondition = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/term-condition`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getTermCondition = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/term-condition`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}


export const addPrivacyPolicy = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/privacy-policy`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getPrivacyPolicy = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/privacy-policy`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const addFaq = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/faq`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getFaq = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/faq`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}


export const getFaqDetail = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/faq/${id}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateFaq = (data: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/content/faq`;
    return axios.put(url, data, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}
