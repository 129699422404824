import * as React from 'react';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from '../../component/common/Loader';
import { onChange, validateForm } from '../../utils';
import CONSTANT from './../../constant';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            name: { value: detail ? detail.name : '', name: 'name', error: '', isRequired: true, options: [] },
            documentFile: { value: '', name: 'documentFile', error: '', isRequired: false },
            categoryImageUrl: detail ? detail.image : undefined,
            showLoader: false,
            fileName: detail ? detail.fileName : ''
        }
    }

    public render() {
        const { showLoader, name, documentFile, categoryImageUrl } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    {
                        showLoader && <Loader />
                    }
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <label>Category Name *</label>
                            <input
                                type="text"
                                className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Enter new category name"
                                name={name.name}
                                value={name.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>Upload image *</label>
                            <input
                                type="file"
                                className={documentFile.error.length > 0 ? "form-control p-1 is-invalid" : "form-control p-1"}
                                name={documentFile.name}
                                onChange={this.onFileChange}
                            />
                            
                            {categoryImageUrl &&
                                <div className="alert alert-success alert-dismissible fade show " role="alert">
                                    <strong>{categoryImageUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onImageDelete}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                        </div>
                        <div className="col-md-3" />
                        <div className="col-md-3" />
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-primary btn-sm btn-sm btn-block">Save Changes</button>
                        </div>
                    </div>
                </form>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    private onFileChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.files[0];
        onChange(this, name, value);
        if (value) {
            this.handleUpload(value);
        }
    }

    handleUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.category}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.category)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ categoryImageUrl: url, showLoader: false, fileName });
                    });
            }
        );
    };

    onImageDelete = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.category)
            .child(this.state.fileName)
            .delete()
            .then(url => {
                this.setState({ categoryImageUrl: '', fileName: '', showLoader: false });
            });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { id, categoryImageUrl } = this.state;
            if (!categoryImageUrl) return toast.error("Please upload category image.");
            let model: any = {
                name: this.state.name.value,
                image: this.state.categoryImageUrl,
                fileName: this.state.fileName
            }
            if (id) {
                model.id = id;
            }
            this.props.onSubmit(model);
        }
    }

}

export default AddComponent;