import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage'; 
import CONFIG from './config';

var firebaseConfig = {
    apiKey: CONFIG.firebase.API_KEY,
    authDomain: CONFIG.firebase.AUTH_DOMAIN,
    databaseURL: CONFIG.firebase.DATABASE_URL,
    projectId: CONFIG.firebase.PROJECT_ID,
    storageBucket: CONFIG.firebase.STORAGE_BUCKET,
    messagingSenderId: CONFIG.firebase.MESSAGING_SENDER_ID,
    appId: CONFIG.firebase.APP_ID,
    measurementId: CONFIG.firebase.MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const App: React.FC = (props: any) => {
    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
}

export default App;