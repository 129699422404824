import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getDashboardStats = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/stats`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCategoryWithCatalogueStats = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/getCatalogueList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCountry = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/master/country`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getState = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/master/state?id=${id}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCity = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/master/city?id=${id}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const addSplash = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/splash`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteSplash = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/splash`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getSplash = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/splash`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const setMobileSplash = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/splash-mobile`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const setTabSplash = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/splash-tab`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}