import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getUserDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/detail`;
    return axios.post(url, model).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const updateUserDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/detail`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const getUserManagementDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/detail`;
    return axios.post(url, model).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const deleteUser = (userId: any, model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/delete/${userId}`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getUserSubscribedPlans = (userId: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/user-all/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const changePassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/change-password`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const getUserList = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/list?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const getUserSearch = (page: number, limit: number, sort: number, keyword: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/search?p=${page}&l=${limit}&s=${sort}&q=${keyword}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}