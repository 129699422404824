import React from 'react';
import { removeStorage, removeAllCookies } from '../../utils';
import CONSTANT from '../../constant';

class Logout extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        removeAllCookies();
        localStorage.removeItem('rsc_cache');
        removeStorage(CONSTANT.keys.token);
        removeStorage(CONSTANT.keys.userDetail);
        window.location.href = '/';
    }

    public render() {
        return (
            <React.Fragment>
                <div className="text-center">Redirecting to home page</div>
            </React.Fragment>
        );
    }
}

export default Logout;