import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getVendorList = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/list?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const getVendorSearch = (page: number, limit: number, sort: number, keyword: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/search?p=${page}&l=${limit}&s=${sort}&q=${keyword}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const getVendorDetail = (model: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/detail`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const deleteVendor = (userId: any, model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/delete/${userId}`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getVendorProducts = (userId: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/product/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const updateVendorDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/detail`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}