import * as React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';

import ModalWindow from '../../component/common/ModalWindow';
import AnimalDetail from '../../component/content-management/AnimalDetail';
import BreedDetail from '../../component/content-management/BreedDetail';
import StudDetail from '../../component/content-management/StudDetail';
import CategoryDetail from '../../component/content-management/CategoryDetail';
import AnimalBreedMap from '../../component/content-management/AnimalBreedMap';
import {
    addAnimalType, addBreed, addStudName, addCategory, getAnimalType,
    getBreedList, getStudNameList, getCategoryList, updateCategory,
    getAllAnimalTypeWithBreed, addAnimalWithBreed, updateAnimalWithBreed,
    deleteAnimalType, deleteAnimalBreed, deleteAnimalStud, deleteCategory,
    updateAnimalType, updateAnimalBreed, updateAnimalStud
} from './../../action/CategoryAction';
import { onChange } from './../../utils';
const camImg2 = require('./../../assets/media/users/user1.jpg');

class Category extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            showAnimalModal: false,
            showBreedModal: false,
            showStudModal: false,
            showCategoryModal: false,
            showAnimalMapModal: false,

            breedAgaintAnimal: [],
            animalList: undefined,
            breedList: undefined,
            studNameList: undefined,
            categoryList: undefined,

            animalTypeId: undefined,
            mapList: [],
            categoryDetail: undefined,
            animalDetail: undefined,
            breedDetail: undefined,
            studDetail: undefined
        }
    }

    componentDidMount() {
        this.getAnimalLoadlist();
    }

    public render() {
        const { showLoader, showAnimalModal, showBreedModal, showStudModal, showCategoryModal, showAnimalMapModal, animalTypeId,
            categoryDetail, animalDetail, breedDetail, studDetail
        } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>

                {
                    showAnimalModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Animal type"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <AnimalDetail
                            onSubmit={this.onSubmitAnimal}
                            onCancel={this.onCancel}
                            detail={animalDetail}
                        />
                    </ModalWindow>
                }
                {
                    showBreedModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Breed"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <BreedDetail
                            onSubmit={this.onSubmitBreed}
                            onCancel={this.onCancel}
                            detail={breedDetail}
                        />
                    </ModalWindow>
                }
                {
                    showStudModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Stud"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <StudDetail
                            onSubmit={this.onSubmitStud}
                            onCancel={this.onCancel}
                            detail={studDetail}
                        />
                    </ModalWindow>
                }
                {
                    showCategoryModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Category"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <CategoryDetail
                            onSubmit={this.onSubmitCategory}
                            onCancel={this.onCancel}
                            detail={categoryDetail}
                        />
                    </ModalWindow>
                }
                {
                    showAnimalMapModal && <ModalWindow
                        className="modal-lg"
                        title="Select Breed"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <AnimalBreedMap
                            animalTypeId={animalTypeId}
                            onSubmit={this.onSubmitAnimalMapping}
                            onCancel={this.onCancel}
                        />
                    </ModalWindow>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Content management
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                Categories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { breedAgaintAnimal, animalList, breedList, studNameList, categoryList } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="kt-portlet">

                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Animal Type
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>No.</th>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                animalList !== undefined && animalList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <a href="#" className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.animalType}</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteAnimalType(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditAnimalType(item)}><i className="fa fa-pencil-alt"></i></button>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddAnimalType}>Add new animal type</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Breeds
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>No.</th>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                breedList !== undefined && breedList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <a href="#" className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.breed}</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteBreed(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditBreed(item)}><i className="fa fa-pencil-alt"></i></button>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddBreed}>Add new breed</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Stud Name
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>No.</th>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                studNameList !== undefined && studNameList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <a href="#" className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.studName}</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteStudName(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditStudName(item)}><i className="fa fa-pencil-alt"></i></button>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddStud}>Add new stud</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Main Categories
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>No.</th>
                                                <th>Category</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                categoryList !== undefined && categoryList.map((catItem: any, catIndex: number) => {
                                                    const url = CONSTANT.url.catalogueDetail.replace(':id', catItem.id);
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{catIndex + 1}</td>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                                                                            <img className="mb-1" height="50" width="50" src={catItem.image} />
                                                                        </div>
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <a href="#" className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{catItem.name}</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteCategory(catItem)}><i className="fa fa-trash-alt"></i></button>
                                                                    <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditCategory(catItem)}><i className="fa fa-pencil-alt"></i></button>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddCategory}>Add new category</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Breed against animal type
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>No.</th>
                                                <th>Animal Type</th>
                                                <th>Breed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                breedAgaintAnimal !== undefined && breedAgaintAnimal.map((item: any, index: number) => {
                                                    const url = CONSTANT.url.catalogueDetail.replace(':id', item.id);
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item.animalType}</td>
                                                                <td>
                                                                    <div className="row col-12">
                                                                        <div className="col-md-12">
                                                                            {item.breeds.map((itemBreed: any) => {
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <label>{itemBreed.breed}</label><br />
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <div className="col-md-12 pull-right">
                                                                            {item.breeds && item.breeds.length > 0 ?
                                                                                <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditBreedAgainstAnimal(item)}><i className="fa fa-pencil-alt"></i></button>
                                                                                :
                                                                                <button type="button" className="btn btn-sm btn-primary pull-right" onClick={() => this.onAddBreedAgainstAnimal(item)}><i className="fa fa-plus"></i>Add</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onSaveDraft = (model: any) => {
        
    }

    getAnimalLoadlist = () => {
        getAnimalType().then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    animalList: res.data,
                });
                this.loadList();
            }
        }).catch((error: any) => {
            console.log(error)
        });
    }

    loadList = () => {
        const { animalList } = this.state;

        this.setState({ showLoader: true });
        getBreedList().then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    breedList: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getStudNameList().then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    studNameList: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getCategoryList().then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    categoryList: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getAllAnimalTypeWithBreed().then((res: any) => {
            if (res.status === 200) {
                const filterBreedsMap = animalList.map((item: any) => {
                    item.breeds = [];
                    for (let i = 0; i < res.data.length; i++) {
                        if (item.id === res.data[i].animalTypeId) {
                            item.breeds = res.data[i].breeds;
                        }
                    }
                    return item;
                })
                this.setState({
                    showLoader: false,
                    breedAgaintAnimal: filterBreedsMap
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onAddAnimalType = () => {
        this.setState({ showAnimalModal: true });
    }

    onAddBreed = () => {
        this.setState({ showBreedModal: true });
    }

    onAddStud = () => {
        this.setState({ showStudModal: true });
    }

    onAddCategory = () => {
        this.setState({ showCategoryModal: true });
    }

    onEdit = (item: any) => {

    }

    onAddBreedAgainstAnimal = (item: any) => {
        this.setState({ animalTypeId: item.id, showAnimalMapModal: true });
    }

    onEditBreedAgainstAnimal = (item: any) => {
        this.setState({ animalTypeId: item.id, showAnimalMapModal: true });
    }

    onEditCategory = (item: any) => {
        this.setState({ categoryDetail: item, showCategoryModal: true });
    }

    onDeleteCategory = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                const reqObj: any = { docId: item.id };
                deleteCategory(reqObj).then((res: any) => {
                    this.setState({ showLoader: false });
                    if (res.status === 200) {
                        toast.success('Success');
                        this.getAnimalLoadlist();
                        this.onCancel();
                    }
                    if (res.status === 400) {
                        toast.error(res.message);
                    }
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onEditAnimalType = (item: any) => {
        this.setState({ animalDetail: item, showAnimalModal: true });
    }

    onDeleteAnimalType = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.animalType}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                const reqObj: any = { docId: item.id };
                deleteAnimalType(reqObj).then((res: any) => {
                    this.setState({ showLoader: false });
                    if (res.status === 200) {
                        toast.success('Success');
                        this.getAnimalLoadlist();
                        this.onCancel();
                    }
                    if (res.status === 400) {
                        toast.error(res.message);
                    }
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onEditBreed = (item: any) => {
        this.setState({ breedDetail: item, showBreedModal: true });
    }

    onDeleteBreed = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.breed}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                const reqObj: any = { docId: item.id };
                deleteAnimalBreed(reqObj).then((res: any) => {
                    this.setState({ showLoader: false });
                    if (res.status === 200) {
                        toast.success('Success');
                        this.getAnimalLoadlist();
                        this.onCancel();
                    }
                    if (res.status === 400) {
                        toast.error(res.message);
                    }
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onEditStudName = (item: any) => {
        this.setState({ studDetail: item, showStudModal: true });
    }

    onDeleteStudName = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.studName}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                const reqObj: any = { docId: item.id };
                deleteAnimalStud(reqObj).then((res: any) => {
                    this.setState({ showLoader: false });
                    if (res.status === 200) {
                        toast.success('Success');
                        this.getAnimalLoadlist();
                        this.onCancel();
                    }
                    if (res.status === 400) {
                        toast.error(res.message);
                    }
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onCancel = () => {
        this.setState({ showAnimalModal: false, showBreedModal: false, showStudModal: false, showCategoryModal: false, showAnimalMapModal: false });
    }

    onSubmitAnimal = (reqObj: any) => {
        this.onCancel();
        this.setState({ showLoader: true });
        if (reqObj.id) {
            updateAnimalType(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    this.getAnimalLoadlist();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        } else {
            addAnimalType(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    this.getAnimalLoadlist();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    onSubmitBreed = (reqObj: any) => {
        this.onCancel();
        this.setState({ showLoader: true });
        if (reqObj.id) {
            updateAnimalBreed(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    this.getAnimalLoadlist();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        } else {
            addBreed(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    this.loadList();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    onSubmitStud = (reqObj: any) => {
        this.onCancel();
        this.setState({ showLoader: true });
        if (reqObj.id) {
            updateAnimalStud(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    this.getAnimalLoadlist();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        } else {
            addStudName(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    this.loadList();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    onSubmitCategory = (reqObj: any) => {
        this.setState({ showLoader: true });
        this.onCancel();
        if (reqObj.id) {
            updateCategory(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    this.loadList();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        } else {
            addCategory(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    toast.success(res.message);
                    this.loadList();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    onSubmitAnimalMapping = (reqObj: any, isEdit: any) => {
        this.onCancel();
        if (isEdit) {
            this.setState({ showLoader: true });
            updateAnimalWithBreed(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    this.loadList();
                    this.onCancel();
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        } else {
            this.setState({ showLoader: true });
            addAnimalWithBreed(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    this.loadList();
                    this.onCancel();
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

}

export default Category;
