import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CONSTANT from './constant';
import { getStorage } from './utils';

// auth routes
import Login from './container/auth/Login';
import Register from './container/auth/Register';
import ForgotPassword from './container/auth/ForgotPassword';
import ChangePassword from './container/auth/ChangePassword';
import Logout from './container/auth/Logout';
// auth routes

import Dashboard from './container/Dashboard';

// Profile route
import MyProfile from './container/my-profile/Profile';
import ProfileEdit from './container/my-profile/ProfileEdit';

// Vendor route
import VendorList from './container/vendor-management/List';
import VendorDetail from './container/vendor-management/Detail';

// Vendor route
import UserList from './container/user-management/List';
import UserDetail from './container/user-management/Detail';

// Plan Management
import AddPlan from './container/plan-management/AddPlan';
import PlanList from './container/plan-management/List';
import VendorPlanList from './container/plan-management/VendorPlanList';
import UserPlanList from './container/plan-management/UserPlanList';
import EditUserPlan from './container/plan-management/EditUserPlan';
import EditVendorPlan from './container/plan-management/EditVendorPlan';

// Coupon Management
import AddCoupon from './container/coupon-management/Add';
import CouponList from './container/coupon-management/List';
import EditUserCoupon from './container/coupon-management/EditUserCoupon';
import EditVendorCoupon from './container/coupon-management/EditVendorCoupon';

// Catalogue Management
import AddCatalogue from './container/catalogue-management/AddCatalogue';
import CatalogueList from './container/catalogue-management/List';
import EditCatalogue from './container/catalogue-management/Edit';
import CatalogueApproval from './container/catalogue-management/Approval';
import CatalogueUpdates from './container/catalogue-management/Updates';

// Content Management
import AboutUs from './container/content-management/AboutUs';
import SupportUs from './container/content-management/Support';
import TermCondition from './container/content-management/TermCondition';
import PrivacyPolicy from './container/content-management/PrivacyPolicy';
import Faq from './container/content-management/Faq';
import EditFaq from './container/content-management/EditFaq';
import Categories from './container/content-management/Categories';
import SplashManagement from './container/content-management/SplashManagement';

// Notification
import Notifications from './container/Notifications';

// misc route
import FourZeroFour from './container/error/FourZeroFour';
import Privacy from './container/Privacy';
// misc route

const PrivateRoute = (props: any) => {
    const token = getStorage(CONSTANT.keys.token);
    if (token) {
        return (
            <React.Fragment>
                <Route exact={true} path={props.path} component={props.component} />
            </React.Fragment>
        )
    } else {
        return (
            <Login />
        )
    }
}

const Router = (props: any) => {
    return (
        <Switch>
            {/* auth routes */}
            <Route exact={true} path={CONSTANT.url.login} component={Login} />
            <Route exact={true} path={CONSTANT.url.register} component={Register} />
            <Route exact={true} path={CONSTANT.url.forgotPassword} component={ForgotPassword} />
            <Route exact={true} path={CONSTANT.url.changePassword} component={ChangePassword} />
            <Route exact={true} path={CONSTANT.url.logout} component={Logout} />
            {/* auth routes */}

            <PrivateRoute exact={true} path={CONSTANT.url.dashboard} component={Dashboard} />

            {/* profile management routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.profile} component={MyProfile} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileEdit} component={ProfileEdit} />
            {/* profile management routes */}

            {/* vendor management routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.vendor} component={VendorList} />
            <PrivateRoute exact={true} path={CONSTANT.url.vendorDetail} component={VendorDetail} />
            {/* vendor management routes */}

            {/* user management routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.user} component={UserList} />
            <PrivateRoute exact={true} path={CONSTANT.url.userDetail} component={UserDetail} />
            {/* user management routes */}

            {/* plan management routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.addPlan} component={AddPlan} />
            <PrivateRoute exact={true} path={CONSTANT.url.planList} component={PlanList} />
            <PrivateRoute exact={true} path={CONSTANT.url.vendorsListWithPlanId} component={VendorPlanList} />
            <PrivateRoute exact={true} path={CONSTANT.url.userListWithPlanId} component={UserPlanList} />
            <PrivateRoute exact={true} path={CONSTANT.url.planUserEdit} component={EditUserPlan} />
            <PrivateRoute exact={true} path={CONSTANT.url.planVendorEdit} component={EditVendorPlan} />
            {/* plan management routes */}

            {/* coupon management routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.addCoupon} component={AddCoupon} />
            <PrivateRoute exact={true} path={CONSTANT.url.couponList} component={CouponList} />
            <PrivateRoute exact={true} path={CONSTANT.url.couponUserEdit} component={EditUserCoupon} />
            <PrivateRoute exact={true} path={CONSTANT.url.couponVendorEdit} component={EditVendorCoupon} />
            {/* coupon management routes */}

            {/* catalogue management routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.addCatalogue} component={AddCatalogue} />
            <PrivateRoute exact={true} path={CONSTANT.url.catalogueList} component={CatalogueList} />
            <PrivateRoute exact={true} path={CONSTANT.url.catalogueDetail} component={EditCatalogue} />
            <PrivateRoute exact={true} path={CONSTANT.url.catalogueApproval} component={CatalogueApproval} />
            <PrivateRoute exact={true} path={CONSTANT.url.catalogueUpdates} component={CatalogueUpdates} />
            {/* catalogue management routes */}

            {/* content management routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.aboutUs} component={AboutUs} />
            <PrivateRoute exact={true} path={CONSTANT.url.support} component={SupportUs} />
            <PrivateRoute exact={true} path={CONSTANT.url.termCondition} component={TermCondition} />
            <PrivateRoute exact={true} path={CONSTANT.url.privacyPolicy} component={PrivacyPolicy} />
            <PrivateRoute exact={true} path={CONSTANT.url.faq} component={Faq} />
            <PrivateRoute exact={true} path={CONSTANT.url.editFaq} component={EditFaq} />
            <PrivateRoute exact={true} path={CONSTANT.url.categories} component={Categories} />
            <PrivateRoute exact={true} path={CONSTANT.url.splash} component={SplashManagement} />
            {/* content management routes */}

            <PrivateRoute exact={true} path={CONSTANT.url.notifications} component={Notifications} />

            <Route exact={true} path={CONSTANT.url.privacy} component={Privacy} />

            <Route component={FourZeroFour} />
        </Switch>
    );
}

export default Router;
