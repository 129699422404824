import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from "firebase/app";

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import { changePassword } from '../../action/UserAction';
import { onChange, validateForm, getStorage } from './../../utils';
const defaultProfile = require("./../../assets/media/users/default.jpg")

class ChangePassword extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            showLoader: false,
            detail: undefined,
            oldPassword: { name: 'oldPassword', value: '', error: '', isRequired: true },
            newPassword: { name: 'newPassword', value: '', error: '', isRequired: true },
            cnfPassword: { name: 'cnfPassword', value: '', error: '', isRequired: true },
            email: undefined
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        if (ud)
            this.setState({ email: ud.email, id: ud.uid })
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }

                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Change Password
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { oldPassword, newPassword, cnfPassword } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-xl-8 col-lg-12 col-md-8 col-sm-8">
                        <div className="card card-custom gutter-b card-stretch">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12">
                                        <form onSubmit={this.onSubmit}>
                                            <div className="row pr-4">

                                                <div className="col-lg-12">
                                                    <div className="row mt-3">
                                                        <div className="col-lg-6 form-group">
                                                            <label>Old Password</label>
                                                            <input
                                                                type="password"
                                                                className={oldPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="Enter old password"
                                                                name={oldPassword.name}
                                                                value={oldPassword.value}
                                                                onChange={this.onChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="col-lg-6 form-group">
                                                            <label>New Password</label>
                                                            <input
                                                                type="password"
                                                                className={newPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="Enter new password"
                                                                name={newPassword.name}
                                                                value={newPassword.value}
                                                                onChange={this.onChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="col-lg-6 form-group">
                                                            <label>Confirm New Password</label>
                                                            <input
                                                                type="password"
                                                                className={cnfPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="Enter confirm new password"
                                                                name={cnfPassword.name}
                                                                value={cnfPassword.value}
                                                                onChange={this.onChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-2 mt-2">
                                                        <div className="col-md-6">
                                                            <button type="submit" className="btn btn-primary btn-sm btn-block p-2">Save</button>
                                                        </div>
                                                        <div className="col-md-6" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { id, email, oldPassword, newPassword, cnfPassword } = this.state;

            if (newPassword.value !== cnfPassword.value) return toast.error("New password and confirm password doesn't match!");

            const model = {
                uid: id,
                password: newPassword.value
            }

            this.setState({ showLoader: true });
            firebase.app().auth().signInWithEmailAndPassword(email, oldPassword.value).then(async (result: any) => {
                this.setState({ showLoader: false });
                if (result) {
                    this.setState({ showLoader: false,  });
                    await changePassword(model);
                    toast.success("Success");
                    window.location.href = CONSTANT.url.dashboard;
                }
            }).catch((error: any) => {
                if (error.code === "auth/wrong-password") {
                    toast.error(error.message);
                }
                this.setState({ showLoader: false });
            });
        }
    }

}

export default ChangePassword;