import * as React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './../../component/common/Loader';
import LoginBG from './../../assets/media/bg/bg-2.jpg';
import Logo4 from './../../assets/media/logos/logo-4.png';

import { onChange, validateForm } from './../../utils';
import CONSTANT from './../../constant';
import { registerUser } from './../../action/AuthAction';

class Login extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstName: { name: 'firstName', value: '', error: '', isRequired: true },
            lastName: { name: 'lastName', value: '', error: '', isRequired: true },
            studName: { name: 'studName', value: '', error: '', isRequired: true },
            tradingName: { name: 'tradingName', value: '', error: '', isRequired: true },
            email: { name: 'email', value: '', error: '', isRequired: true },
            phone: { name: 'phone', value: '', error: '', isRequired: true },
            address: { name: 'address', value: '', error: '', isRequired: true },
            website: { name: 'website', value: '', error: '', isRequired: true },
            password: { name: 'password', value: '', error: '', isRequired: true },
            cnfpassword: { name: 'cnfpassword', value: '', error: '', isRequired: true },
            showLoader: false
        }
    }

    public render() {
        const { firstName, lastName, studName, tradingName, email, phone, address, website, password, cnfpassword, showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--ver kt-grid--root">
                    <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">

                            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside" style={{ backgroundImage: `url(${LoginBG})` }}>
                                <div className="kt-grid__item">
                                    <a href="#" className="kt-login__logo">
                                        <img src={Logo4} alt="" />
                                    </a>
                                </div>
                                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                                    <div className="kt-grid__item kt-grid__item--middle">
                                        
                                    </div>
                                </div>
                                <div className="kt-grid__item">
                                    <div className="kt-login__info">
                                        <div className="kt-login__copyright">
                                            © {new Date().getFullYear()} Studbooks
								    </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">

                                <div className="kt-login__body">

                                    <div className="kt-login__form">
                                        <div className="kt-login__title">
                                            <h3>Register Now</h3>
                                        </div>

                                        <form className="kt-form" onSubmit={this.submitForm}>
                                            <div className="form-group">
                                                <input
                                                    className={firstName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="First Name"
                                                    name={firstName.name}
                                                    value={firstName.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={lastName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="Last Name"
                                                    name={lastName.name}
                                                    value={lastName.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={studName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="Stud Name"
                                                    name={studName.name}
                                                    value={studName.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={tradingName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="Trading Name"
                                                    name={tradingName.name}
                                                    value={tradingName.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="email"
                                                    placeholder="Email"
                                                    name={email.name}
                                                    value={email.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={phone.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="Phone no."
                                                    name={phone.name}
                                                    value={phone.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={address.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="Address"
                                                    name={address.name}
                                                    value={address.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={website.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="Website"
                                                    name={website.name}
                                                    value={website.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={password.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="password"
                                                    placeholder="Password"
                                                    name={password.name}
                                                    value={password.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    className={cnfpassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    name={cnfpassword.name}
                                                    value={cnfpassword.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                            <div className="row mt-2">
                                                <button type="button" className="btn btn-primary col-lg-5 ">
                                                    <a style={{ color: '#fff' }} href={"/"}>
                                                        Previous
                                                    </a>
                                                </button>
                                                <div className="col-lg-2"></div>
                                                <button type="submit" className="btn btn-primary col-lg-5">
                                                    Register
                                            </button>
                                            </div>

                                            <div className="row mt-5">
                                                <span className="kt-login__signup-label">Already have an account?</span>&nbsp;&nbsp;
                                            <Link to={CONSTANT.url.login} className="kt-link kt-login__signup-link">Login</Link>
                                            </div>

                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    private submitForm = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                firstName: st.firstName.value,
                lastName: st.lastName.value,
                studName: st.studName.value,
                tradingName: st.tradingName.value,
                email: st.email.value,
                phone: st.phone.value,
                address: st.address.value,
                website: st.website.value,
                password: st.password.value,
                cnfpassword: st.cnfpassword.value,
                role: 'vendor'
            };
            this.setState({ showLoader: true });
            registerUser(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    this.props.history.push(CONSTANT.url.dashboard);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });;
        }
    }
}

export default Login;