import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import AddUserPlanComp from './../../component/plan-management/AddUserPlan';
import { getUserPlanDetail, updateUserPlanDetail } from '../../action/PlanAction';

class EditUserPlan extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            id: props.match.params.id,
            detail: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Plan Management
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                Edit Plan
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">

                                {detail &&
                                    <AddUserPlanComp
                                        onSubmit={this.onUserSubmit}
                                        detail={detail}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { id } = this.state;
        getUserPlanDetail(id).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({
                    detail: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, vendorPlanList: [], totalVendorPlan: 0, });
        });
    }

    onUserSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateUserPlanDetail(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Plan Successfully added!");
                this.props.history.push(CONSTANT.url.planList);
            }
            if (res.status === 400) {
                toast.error(res.message);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default EditUserPlan;