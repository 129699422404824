import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const createCatalogue = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/newCatalogue`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCatalogue = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/getCatalogue`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCatalogueDetail = (docId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/getCatalogueDetail/${docId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateCatalogueDetail = (model: any, docId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/updateCatalogue/${docId}`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const approveCatalogue = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/approveCatalogue`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCategoryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/category`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getStudnameList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getStudNameList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getAnimalTypeList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getAnimalTypeList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getBreeds = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getBreedList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const postUpdates = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/updates`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getUpdates = (catalogueId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/updates/${catalogueId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getAllVendorList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor-all/list`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        console.log(error.response);
        throw error.response;
    });
}

export const getStudnameListById = (uid: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getStudNameList?uid=${uid}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteCatalogue = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}