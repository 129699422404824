import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import AddCouponDetail from './../../component/coupon-management/AddDetail';
import { createCoupon } from '../../action/CouponAction';
import { onChange } from './../../utils';

class AddVendorPlan extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            planType: { name: 'planType', value: 'vendor', error: '', isRequired: false },
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Coupon Management
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                New Coupon
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { planType } = this.state;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">

                            <div className="kt-portlet__body">
                                <div className="form-group row">
                                    <div className="col-3 col-form-label">
                                        <div className="radio-inline">
                                            <label className="radio radio-primary">
                                                <input type="radio" name={planType.name} className="mr-2" value="vendor" checked={planType.value === 'vendor'} onChange={this.onChangePlan} />
                                                <span>Vendor Coupon</span>
                                            </label>
                                            <label className="radio radio-primary ml-5">
                                                <input type="radio" name={planType.name} className="mr-2" value="user" checked={planType.value === 'user'} onChange={this.onChangePlan} />
                                                <span>User Coupon</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <AddCouponDetail
                                    onSubmit={this.onSubmit}
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    private onChangePlan = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onSubmit = (model: any) => {
        if (this.state.planType.value === "vendor") {
            model.type = "vendor"
        } else {
            model.type = "user"
        }
        this.setState({ showLoader: true });
        createCoupon(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 201) {
                toast.success("New coupon has been added!");
                window.location.href = CONSTANT.url.couponList;
            }
            if (res.status === 400) {
                toast.error(res.message);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default AddVendorPlan;
