import * as React from 'react';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import Loader from '../../component/common/Loader';
import Dropzone from './../common/Dropzone';
import { onChange, validateForm, setOptions, getStorage } from './../../utils';
import CONSTANT from '../../constant';
import { getCatalogueDetail, getCategoryList, getAnimalTypeList, getAllVendorList, getStudnameListById, deleteCatalogue } from './../../action/CatalogueAction';
import { getCountry, getState, getCity } from './../../action/MasterAction';
import { getBreedWithAnimal } from './../../action/CategoryAction';
import ModalWindow from '../../component/common/ModalWindow';
import StudDetail from '../../component/content-management/StudDetail';
import { addStudName, updateAnimalStud } from './../../action/CategoryAction';
import CONFIG from './../../config';

const BannerBgImg1 = require('./../../assets/media/users/100_1.jpg');
const camImg = require('./../../assets/images/image.png');
const camImg2 = require('./../../assets/images/camera-icon.svg');

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        const saleTypeDrop = [
            { id: 1, name: 'On Property (Open Cry)', value: 'On Property (Open Cry)' },
            { id: 2, name: 'On Property (Helmsman)', value: 'On Property (Helmsman)' },
            { id: 3, name: 'On Property (Online)', value: 'On Property (Online)' },
            { id: 4, name: 'Online (Timed Auction)', value: 'Online (Timed Auction)' },
            { id: 5, name: 'Multi-Vendor (Open Cry)', value: 'Multi-Vendor (Open Cry)' },
            { id: 6, name: 'Multi-Vendor (Helmsman)', value: 'Multi-Vendor (Helmsman)' },
            { id: 7, name: 'Off Farm (Open Cry)', value: 'Off Farm (Open Cry)' },
            { id: 8, name: 'Off Farm (Online/Helmsman)', value: 'Off Farm (Online/Helmsman)' },
        ]
        this.state = {
            id: props.id,
            detail,
            currentUserId: undefined,
            title: { name: 'title', value: detail && detail.title ? detail.title : '', error: '', isRequired: true },
            dateRangeFrom: { name: 'dateRangeFrom', value: detail && detail.rangeFromDate ? detail.rangeFromDate : '', error: '', isRequired: true },
            dateRangeTo: { name: 'dateRangeTo', value: detail && detail.rangeToDate ? detail.rangeToDate : '', error: '', isRequired: true },
            catalogueStatus: { name: 'catalogueStatus', value: detail && detail.status ? detail.status : '', error: '', isRequired: true },

            saleDateFrom: { name: 'saleDateFrom', value: detail && detail.saleFromDate ? detail.saleFromDate : '', error: '', isRequired: true },
            saleDateTo: { name: 'saleDateTo', value: detail && detail.saleToDate ? detail.saleToDate : '', error: '', isRequired: true },
            saleType: { name: 'saleType', value: '', error: '', isRequired: true, options: saleTypeDrop },
            categoryId: { name: 'categoryId', value: detail && detail.categoryId ? detail.categoryId : '', error: '', isRequired: true, options: [] },

            saleLocation: { name: 'saleLocation', value: detail && detail.saleLocation ? detail.saleLocation : '', error: '', isRequired: false },

            country: { name: 'country', value: detail && detail.country ? detail.country : '', error: '', isRequired: true, options: [] },
            state: { name: 'state', value: detail && detail.state ? detail.state : '', error: '', isRequired: true, options: [] },
            city: { name: 'city', value: detail && detail.city ? detail.city : '', error: '', isRequired: false, options: [] },
            postCode: { name: 'postCode', value: detail && detail.postCode ? detail.postCode : '', error: '', isRequired: true, options: [] },
            animalType: { name: 'animalType', value: '', error: '', isRequired: false, options: [] },
            studName: { name: 'studName', value: '', error: '', isRequired: false, options: [] },
            breed: { name: 'breed', value: '', error: '', isRequired: false, options: [] },
            number: { name: 'number', value: '', error: '', isRequired: false, options: [] },

            updates: { name: 'updates', value: '', error: '', isRequired: false, options: [] },
            vendorDetail: { name: 'vendorDetail', value: detail && detail.createdBy ? detail.createdBy : '', error: '', isRequired: true, options: [] },
            vendorName: { name: 'vendorName', value: detail && detail.vendorName ? detail.vendorName : '', error: '', isRequired: false },

            studData: detail && detail.studDetail ? detail.studDetail : [],

            thumbnailUrl: detail && detail.thumbnailImage ? detail.thumbnailImage : undefined,
            catalogueUrl: detail && detail.cataloguePdf ? detail.cataloguePdf : undefined,
            thumbnailFileName: detail && detail.thumbnailFileName ? detail.thumbnailFileName : undefined,
            cataloguePdfFileName: detail && detail.latitude ? detail.cataloguePdfFileName : undefined,
            showLoader: false,
            isLocationSelected: detail && detail.latitude && detail.longitude ? true : false,
            latitude: detail && detail.latitude ? detail.latitude : undefined,
            longitude: detail && detail.latitude ? detail.longitude : undefined,
            studDetail: undefined
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ currentUserId: ud.uid });
        const { detail, saleType } = this.state;

        if (detail && detail.saleType) {
            let temp: any = [];
            let saleTemp: any = [];
            temp = detail.saleType.split(", ");
            for (let a = 0; a < temp.length; a++) {
                temp[a] = temp[a]
            }
            const filterSType = saleType.options.map((i: any) => {
                for (let a = 0; a < temp.length; a++) {
                    if (i.name === temp[a]) {
                        saleTemp.push(i)
                    }
                }
                return i;
            });
            this.setState({
                saleType: { ...this.state.saleType, value: saleTemp }
            }, () => {
                this.getStudNameByIdList(detail.createdBy);
            });

        }
        this.loadList();
        this.nearMe();
    }

    public render() {
        const {
            id, title, dateRangeFrom, dateRangeTo, catalogueStatus, saleDateFrom, saleDateTo, saleType, categoryId, saleLocation,
            country, state, city, animalType, studName, breed, number, studData, showLoader, thumbnailUrl, catalogueUrl, updates,
            postCode, isLocationSelected, location, showStudModal, studDetail, saleAddress, vendorDetail, vendorName
        } = this.state;
        const url = id ? CONSTANT.url.catalogueUpdates.replace(':id', id) : '';
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            {id ?
                                <div className="row col-12">
                                    <div className="col-lg-8 form-group">
                                        <input
                                            type="text"
                                            className={title.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            placeholder="Catalogue Title"
                                            name={title.name}
                                            value={title.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-lg-2 ml-0 mt-1">
                                        {id ?
                                            <button type="submit" className="btn btn-primary btn-sm btn-block">Save Changes</button>
                                            : <button type="submit" className="btn btn-primary btn-sm btn-block">Submit</button>}
                                    </div>
                                    <div className="col-lg-2 ml-0 mt-1">
                                        {id &&
                                            <button type="button" className="btn btn-danger btn-sm btn-block" onClick={this.deleteCatalogue}>Delete</button>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="row col-12">
                                    <div className="col-lg-10 form-group">
                                        <input
                                            type="text"
                                            className={title.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            placeholder="Catalogue Title"
                                            name={title.name}
                                            value={title.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-lg-2 ml-0 mt-1">
                                        <button type="submit" className="btn btn-primary btn-sm btn-block">Submit</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-4">
                            <div className="addThumbnail bg-light-gray">
                                <div className="text-center">
                                    {thumbnailUrl ?
                                        <div className="addImage d-table m-auto">
                                            <img src={thumbnailUrl} alt="image" height="250" width="300" />
                                        </div>
                                        : <div className="addImage d-table m-auto">
                                            <img src={camImg} alt="image" />
                                            <h6 className="mt-1">Add a thumbnail image</h6>
                                            <input type="file" accept="image/*" onChange={this.onThumbnailAdded} />
                                        </div>
                                    }
                                </div>
                                {thumbnailUrl &&
                                    <div className="text-center alert alert-success alert-dismissible fade show mt-2" role="alert">
                                        <strong className="mt-3">{thumbnailUrl}</strong>
                                        <button type="button" className="btn close" aria-label="Close" onClick={this.onThumbnailDelete}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-8">
                            {catalogueUrl ?
                                <embed src={catalogueUrl} type="application/pdf" width="100%" height="250px" />
                                :
                                <Dropzone
                                    acceptFile={this.onFilesAdded}
                                    title={"Drag and drop catalogue pdf"}
                                />
                            }
                            {catalogueUrl &&
                                <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                    <strong>{catalogueUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onPDFDelete}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-lg-8">
                            <div className="form-group row">
                                <label className="col-3 col-form-label">Catalogue Live Date</label>
                                <label className="col-1 col-form-label">From</label>
                                <div className="col-3">
                                    <input
                                        type="date"
                                        className={dateRangeFrom.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter duration"
                                        name={dateRangeFrom.name}
                                        value={dateRangeFrom.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <label className="col-1 col-form-label">To</label>
                                <div className="col-4">
                                    <input
                                        type="date"
                                        className={dateRangeTo.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter duration"
                                        name={dateRangeTo.name}
                                        value={dateRangeTo.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group row">
                                <label className="col-5 col-form-label">Catalogue Status</label>
                                <div className="col-lg-7 form-group">
                                    <select
                                        className={catalogueStatus.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                        name={catalogueStatus.name}
                                        value={catalogueStatus.value}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select catalogue status</option>
                                        <option value="Draft">Draft</option>
                                        <option value="Live">Live</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group row">
                                <label className="col-3 col-form-label">Sale Date</label>
                                <label className="col-1 col-form-label">From</label>
                                <div className="col-3">
                                    <input
                                        type="date"
                                        className={saleDateFrom.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter duration"
                                        name={saleDateFrom.name}
                                        value={saleDateFrom.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <label className="col-1 col-form-label">To</label>
                                <div className="col-4">
                                    <input
                                        type="date"
                                        className={saleDateTo.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter duration"
                                        name={saleDateTo.name}
                                        value={saleDateTo.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group row">
                                <label className="col-5 col-form-label">Category</label>
                                <div className="col-7 form-group">
                                    <select
                                        className={categoryId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        name={categoryId.name}
                                        value={categoryId.value}
                                        onChange={this.onChange}>
                                        <option value="">Select category</option>
                                        {
                                            categoryId.options.map(function (item: any, ind: any) {
                                                return (
                                                    <option key={ind} id={item.id} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="row col-lg-6">
                            <label className="col-4 col-form-label">Sale Type</label>
                            <div className="col-lg-8 form-group">
                                <Typeahead
                                    id="ta-permission-ids"
                                    allowNew={false}
                                    labelKey={(option: any) => `${option.name}`}
                                    name={saleType.name}
                                    selected={saleType.value}
                                    multiple={true}
                                    options={saleType.options}
                                    onChange={(e: any) => this.typeaheadOnChange(saleType.name, e)}
                                    placeholder="Select sale type"
                                    isInvalid={saleType.error.length > 0}
                                />
                            </div>
                        </div>

                        <div className="row col-lg-4">
                            <label className="col-5 col-form-label">Add Vendor</label>
                            <div className="col-lg-7 form-group">
                                <select
                                    className={vendorDetail.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    name={vendorDetail.name}
                                    value={vendorDetail.value}
                                    onChange={this.onChangeVendor}>
                                    <option value="">Select vendor</option>
                                    {
                                        vendorDetail.options.map(function (item: any, ind: any) {
                                            return (
                                                <option key={ind} id={item.id} value={item.id}>{item.displayName}</option>
                                            )
                                        })
                                    }
                                    <option value="others">Others</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 form-group">
                            <input
                                type="text"
                                className={vendorName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Enter Vendor Name"
                                name={vendorName.name}
                                value={vendorName.value}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>


                    <h5>Sale Location</h5>
                    <div className="row">
                        <div className="col-lg-6 form-group row">
                            <div className="col-lg-12">
                                <GooglePlacesAutocomplete
                                    apiKey={CONFIG.placeAPIKey}
                                    selectProps={{
                                        defaultInputValue: saleLocation.value,
                                        onChange: this.onChangeAuto,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 form-group">
                            <select
                                className={country.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                name={country.name}
                                value={country.value}
                                onChange={this.onChange}
                            >
                                <option value="">Select country</option>
                                {
                                    country.options.map(function (item: any, ind: any) {
                                        return (
                                            <option key={ind} id={item.id} value={item.name}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-2 form-group">
                            <select
                                className={state.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                name={state.name}
                                value={state.value}
                                onChange={this.onChange}
                            >
                                <option value="">Select state</option>
                                {
                                    state.options.map(function (item: any, ind: any) {
                                        return (
                                            <option key={ind} id={item.id} value={item.name}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-2 form-group">
                            <input
                                type="text"
                                className={postCode.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Enter post code"
                                name={postCode.name}
                                value={postCode.value}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>

                    <h5>Stud Details</h5>
                    <div className="row p-2">
                        <table className="table table-bordered table-hover">
                            <thead className="bg-light">
                                <tr>
                                    <th style={{ maxWidth: "4rem" }}>Stud Name</th>
                                    <th style={{ maxWidth: "4rem" }}>Animal Type</th>
                                    <th style={{ maxWidth: "4rem" }}>Breed</th>
                                    <th style={{ maxWidth: "4rem" }}>Lots</th>
                                    <th style={{ maxWidth: "4rem" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    studData !== undefined && studData.map((item: any, addIndex: any) => {
                                        const url = CONSTANT.url.catalogueDetail.replace(':id', item.id);
                                        return (
                                            <React.Fragment key={addIndex}>
                                                <tr>
                                                    <td>{item.studName}</td>
                                                    <td>{item.animalType}</td>
                                                    <td>{item.breed}</td>
                                                    <td>{item.number}</td>
                                                    <td>
                                                        <button className="btn btn-sm" onClick={() => this.onAddDelete(addIndex)}>
                                                            <i className="fa fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-3">
                            <button type="button" className="btn kt-font-brand" onClick={this.onAddNewStud}>Add new stud</button>
                        </div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 form-group">
                            <select
                                className={studName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                name={studName.name}
                                value={studName.value}
                                onChange={this.onChange}
                            >
                                <option value="">Select stud name</option>
                                {
                                    studName.options.map(function (item: any, ind: any) {
                                        return (
                                            <option key={ind} id={item.id} value={item.name}>{item.studName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 form-group">
                            <select
                                className={animalType.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                name={animalType.name}
                                value={animalType.value}
                                onChange={this.onChange}
                            >
                                <option value="">Select animal type</option>
                                {
                                    animalType.options.map(function (item: any, ind: any) {
                                        return (
                                            <option key={ind} id={item.id} value={item.name}>{item.animalType}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 form-group">
                            <select
                                className={breed.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                name={breed.name}
                                value={breed.value}
                                onChange={this.onChange}
                            >
                                <option value="">Select breed</option>
                                {
                                    breed.options.map(function (item: any, ind: any) {
                                        return (
                                            <option key={ind} id={item.id} value={item.name}>{item.breed}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 form-group">
                            <NumberFormat
                                className={number.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Enter lots"
                                name={number.name}
                                maxLength={5}
                                allowNegative={false}
                                value={number.value}
                                allowLeadingZeros={true}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-11"></div>
                        <div className="col-lg-1 mr-0">
                            <button type="button" className="btn btn-sm font-weight-bolder btn-primary" onClick={this.onAddStud}>
                                <i className="la la-plus"></i>Add</button>
                        </div>
                    </div>

                    {id &&
                        <React.Fragment>
                            <div className="row mt-3 border p-2">

                                <div className="row col-12">
                                    <div className="col-6 pt-1">
                                        <h5>Sale Notifications</h5>
                                    </div>
                                    <div className="col-6 pull-righ">
                                        <Link to={url} className="pull-right">View All</Link>
                                    </div>
                                </div>

                                <div className="form-group col-lg-12 mt-2">
                                    <input
                                        type="text"
                                        className={updates.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Write an update here eg. Sale starts tomorrow, Supplementary Sheet available now..."
                                        name={updates.name}
                                        value={updates.value}
                                        onChange={this.onChange}
                                    />
                                </div>

                            </div>
                        </React.Fragment>
                    }
                </form>
                {
                    showLoader && <Loader />
                }
                {
                    showStudModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Stud"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <StudDetail
                            onSubmit={this.onSubmitStud}
                            onCancel={this.onCancel}
                            detail={studDetail}
                        />
                    </ModalWindow>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === this.state.country.name) {
            let id = e.target[e.target.selectedIndex].id
            this.getStateList(id);
        }

        if (name === this.state.state.name) {
            let id = e.target[e.target.selectedIndex].id;
            this.getCityList(id);
        }

        if (name === this.state.animalType.name) {
            let id = e.target[e.target.selectedIndex].id
            this.getBreedList(id);
        }

        if (name === this.state.vendorDetail.name) {
            let id = e.target[e.target.selectedIndex].id
            this.getStudNameByIdList(id);
        }

        onChange(this, name, value);
    }

    private onChangeVendor = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        if (value === 'others') {
            this.setState({
                vendorName: { ...this.state.vendorName, isRequired: true },
            });
        } else {
            this.setState({
                vendorName: { ...this.state.vendorName, isRequired: false, value: '' },
            });

        }
        onChange(this, name, value);
    }

    onChangeAuto = (e: any) => {
        geocodeByAddress(e.label)
            .then((results: any) => getLatLng(results[0]))
            .then(({ lat, lng }: any) => {
                this.setState({
                    isLocationSelected: true,
                    latitude: lat,
                    longitude: lng,
                    saleLocation: { ...this.state.saleLocation, value: e.label },
                    address: e.label
                });
            });
    }

    typeaheadOnChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    loadList = () => {
        const { id } = this.state;
        this.setState({ showLoader: true });
        getCategoryList().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.categoryId.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getAllVendorList().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.vendorDetail.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getCountry().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.country.name, res.data);
                const filterMapCountry = res.data.filter((i: any) => i.name === this.state.country.value);
                if (filterMapCountry.length > 0) {
                    this.getStateList(filterMapCountry[0].id);
                }
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        
        getAnimalTypeList().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.animalType.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    getStateList = (id: any) => {
        this.setState({ showLoader: true });
        getState(id).then((res: any) => {
            if (res.status === 200) {
                this.setState({ showLoader: false });
                setOptions(this, this.state.state.name, res.data);

            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    getCityList = (id: any) => {
        this.setState({ showLoader: true });
        getCity(id).then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.city.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    getBreedList = (animalTypeId: any) => {
        this.setState({ showLoader: true });
        getBreedWithAnimal(animalTypeId).then((res: any) => {
            if (res.status === 200) {
                const breedList = res.data.length > 0 ? res.data[0].breeds : [];
                setOptions(this, this.state.breed.name, breedList);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    getStudNameByIdList = (vendorId: any) => {
        this.setState({ showLoader: true });
        getStudnameListById(vendorId).then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.studName.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onThumbnailAdded = (e: any) => {
        const f = e.target.files;
        var file = f[0];
        if (file) {
            this.handleThumbnailUpload(file);
        }
        e.target.value = '';
    }

    onFilesAdded = (files: any) => {
        var file = files[0];
        this.handleUpload(file);
    }

    handleThumbnailUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.catalogueThumbnail}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.catalogueThumbnail)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ thumbnailUrl: url, thumbnailFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onThumbnailDelete = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.catalogueThumbnail)
            .child(this.state.thumbnailFileName)
            .delete()
            .then(url => {
                this.setState({ thumbnailFileName: "", thumbnailUrl: "", showLoader: false });
            });
    }

    handleUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.catalogues}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.catalogues)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ catalogueUrl: url, cataloguePdfFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onPDFDelete = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.catalogues)
            .child(this.state.cataloguePdfFileName)
            .delete()
            .then(url => {
                this.setState({ cataloguePdfFileName: "", catalogueUrl: "", showLoader: false });
            });
    }

    onAddStud = async () => {
        let isValidForm = false;
        this.setState({
            studName: { ...this.state.studName, isRequired: true, },
            animalType: { ...this.state.animalType, isRequired: true, },
            breed: { ...this.state.breed, isRequired: true, },
            number: { ...this.state.number, isRequired: true, }
        });
        isValidForm = await validateForm(this)
        if (await validateForm(this)) {
            const { animalType, studName, breed, number, studData } = this.state;
            let studArr = studData;
            const newObj = {
                animalType: animalType.value,
                studName: studName.value,
                breed: breed.value,
                number: parseInt(number.value, 10),
            }
            studArr.push(newObj);
            this.setState({ studData: studArr });
            this.setState({
                studName: { ...this.state.studName, value: '' },
                animalType: { ...this.state.animalType, value: '' },
                breed: { ...this.state.breed, value: '' },
                number: { ...this.state.number, value: '' }
            });
        }

    }

    onAddDelete = (index: any) => {
        const { studData } = this.state;
        let modArr = studData;
        modArr.splice(index, 1);
        this.setState({ studData: modArr });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        this.setState({
            studName: { ...this.state.studName, isRequired: false },
            animalType: { ...this.state.animalType, isRequired: false },
            breed: { ...this.state.breed, isRequired: false },
            number: { ...this.state.number, isRequired: false }
        }, () => {

            if (validateForm(this)) {
                const { title, dateRangeFrom, dateRangeTo, catalogueStatus, saleDateFrom, saleDateTo, saleType, categoryId, vendorName,
                    saleLocation, country, state, city, animalType, studName, breed, number, studData, thumbnailUrl, catalogueUrl, currentUserId,
                    updates, thumbnailFileName, cataloguePdfFileName, postCode, isLocationSelected, latitude, longitude, saleAddress, vendorDetail
                } = this.state;

                if (!isLocationSelected) return toast.error("Please select sale location.");
                if (!thumbnailUrl) return toast.error("Please upload thumbnail image.");
                if (!catalogueUrl) return toast.error("Please upload catalogue pdf.");

                const filertCat = categoryId.options.filter((i: any) => i.id == categoryId.value);
                let saleT = saleType.value ? saleType.value.map((i: any) => i.name) : '';

                const model: any = {
                    categoryName: filertCat[0].name,
                    categoryImage: filertCat[0].image,
                    categoryId: filertCat[0].id,
                    createdBy: vendorDetail.value === 'others' ? currentUserId : vendorDetail.value,
                    catelogue: {
                        title: title.value,
                        thumbnailImage: thumbnailUrl,
                        cataloguePdf: catalogueUrl,
                        rangeFromDate: dateRangeFrom.value,
                        rangeToDate: dateRangeTo.value,
                        status: catalogueStatus.value,
                        saleFromDate: saleDateFrom.value,
                        saleToDate: saleDateTo.value,
                        saleType: saleT ? saleT.join(', ') : '',
                        saleLocation: saleLocation.value,
                        country: country.value,
                        state: state.value,
                        city: city.value,
                        postCode: postCode.value,
                        latitude: latitude ? latitude : 0,
                        longitude: longitude ? longitude : 0,
                        studDetail: studData,
                        isApproved: true,
                        thumbnailFileName,
                        cataloguePdfFileName,
                        vendorName: vendorName.value
                    }
                };
                if (this.state.id) {
                    model.id = this.state.id;
                    model.updates = updates.value;
                }
                this.props.onSubmit(model);
            }
        });
    }

    onCancel = () => {
        this.setState({ showStudModal: false });
    }

    onAddNewStud = () => {
        this.setState({ showStudModal: true });
    }

    onNearMe = () => {
        this.nearMe();
    }

    nearMe = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                if (position) {
                    this.setState({
                        isLocationSelected: true,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                }
            });
        } else {
            console.log("Not Available");
        }
    }

    onSubmitStud = (reqObj: any) => {
        this.onCancel();
        if (!this.state.vendorDetail.value) return toast.error("Please select vendor");

        this.setState({ showLoader: true });
        let reqModel = reqObj;
        reqModel.uid = this.state.vendorDetail.value;
        if (reqModel.id) {
            updateAnimalStud(reqModel).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    this.loadList();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        } else {
            addStudName(reqModel).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    toast.success("New stud added.");
                    this.getStudNameByIdList(this.state.vendorDetail.value);
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    deleteCatalogue = () => {
        const { detail } = this.state;
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete catalogue ${detail.title}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                deleteCatalogue({ catalogueId: detail.catalogueId, categoryId: detail.categoryId }).then((res: any) => {
                    this.setState({ showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Catalogue has been deleted.',
                        'success'
                    )
                    window.location.href = CONSTANT.url.catalogueList;
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your catalogue is safe :)',
                    'error'
                )
            }
        })
    }
}

export default AddComponent;