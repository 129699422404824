import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import { postUpdates, getUpdates } from '../../action/CatalogueAction';
import { onChange, validateForm } from './../../utils';

class CatalogueUpdates extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            showLoader: false,
            list: undefined,
            updates: { name: 'updates', value: '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }

                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Catalogue
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                Updates
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { updates, list } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            <form onSubmit={this.onSubmit}>
                                <div className="mt-3">
                                    <h5>Sale Notifications</h5>
                                    <div className="row">
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="text"
                                                className={updates.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Write an update here eg. Sale starts tomorrow, Supplementary Sheet available now..."
                                                name={updates.name}
                                                value={updates.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-2 pr-4">
                                            <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="kt-portlet">

                        <div className="kt-portlet__body">

                            <div className="mb-2">
                                <div className="row col-12 border p-4">
                                    <div className="col-2">
                                        <h5>Date</h5>
                                    </div>
                                    <div className="col-10 ">
                                        <label className="font-weight-bold">
                                            <h5>Message</h5>
                                        </label>
                                    </div>
                                </div>
                                {list && list.map((item: any) => {
                                    return (
                                        <div className="row col-12 p-4 border">
                                            <div className="col-2">
                                                <span className="text-muted font-weight-bold">{moment(item.createdOn).format("DD/MM/YYYY")}</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="text-muted font-weight-bold">{item.update}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    loadList = () => {
        const { id } = this.state;
        this.setState({ showLoader: true });
        getUpdates(id).then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    list: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, list: [] });
        });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { id, updates } = this.state;

            this.setState({ showLoader: true });
            const model = {
                catalogueId: id,
                update: updates.value
            }
            postUpdates(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    toast.success("New Updates added!");
                    this.loadList();
                    this.setState({ updates })
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

}

export default CatalogueUpdates;