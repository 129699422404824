import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Header from './../component/common/Header';
import Sidebar from '../component/common/Sidebar';
import Footer from '../component/common/Footer';
import Loader from '../component/common/Loader';
import { getUpdates } from '../action/CatalogueAction';

class Notification extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            list: undefined,

        }
    }

    componentDidMount() {
        // this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Notifications
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                
                <div className="row">
                    <div className="kt-portlet">

                        <div className="kt-portlet__body">

                            <div className="mb-2">
                                <div className="row col-12 border p-4">
                                    <div className="col-2">
                                        <h5>Date</h5>
                                    </div>
                                    <div className="col-10 ">
                                        <label className="font-weight-bold">
                                            <h5>Message</h5>
                                        </label>
                                    </div>
                                </div>
                                {list && list.map((item: any) => {
                                    return (
                                        <div className="row col-12 p-4 border">
                                            <div className="col-2">
                                                <span className="text-muted font-weight-bold">{moment(item.createdOn).format("DD/MM/YYYY")}</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="text-muted font-weight-bold">{item.update}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        )
    }

    loadList = () => {
        const { id } = this.state;
        getUpdates(id).then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    list: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({
                showLoader: false,
                list: [],
            });
        });
    }

}

export default Notification;