import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import CONSTANT from './../../constant';
import { onChange } from './../../utils';
import { getCatalogue, deleteCatalogue } from './../../action/CatalogueAction';

class CatalogueList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 10,
            sort: 1,
            count: 0,
            showLoader: false,
            keyword: { name: 'keyword', value: '', error: '', isRequired: false },
            planType: { name: 'planType', value: 'vendor', error: '', isRequired: false },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }

                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Catalogues
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, keyword } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">

                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <div className="kt-input-icon kt-input-icon--right kt-subheader__search" style={{ width: 300 }}>
                                        <input name={keyword.name} type="text" className="form-control" placeholder="Search" value={keyword.value} onChange={this.search} />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span className="svg-icon svg-icon-lg">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <a href={CONSTANT.url.addCatalogue} className="btn btn-brand btn-elevate btn-icon-sm">
                                        New Catalogue <i className="la la-plus ml-3"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        {list !== undefined && this.renderTable()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    renderTable = () => {
        const { filterList } = this.state;

        const rowStyle = (row: any, rowIndex: any) => {
            if (rowIndex % 2 === 0) {
                return {
                    backgroundColor: '#efefef'
                };
            }
            return {
                backgroundColor: '#ffffff'
            };
        }
        const columns = [{
            dataField: 'index',
            text: 'No.',
            formatter: this.indexCellFormatter,
        }, {
            dataField: 'title',
            text: 'Catalogue Title',
            sort: true,
        }, {
            dataField: 'vendorName',
            text: 'Vendor',
            formatter: this.vNameCellFormatter,
            sort: true
        }, {
            dataField: 'vendorEmail',
            text: 'Email',
            formatter: this.emailCellFormatter,
            sort: true
        }, {
            dataField: 'tradingName',
            text: 'Trading Name',
            formatter: this.tradingCellFormatter,
            sort: true
        }, {
            dataField: 'categoryName',
            text: 'Category',
            formatter: this.categoryCellFormatter,
            sort: true
        }, {
            dataField: 'createdOn',
            text: 'Date Submitted',
            formatter: this.dateCellFormatter,
            sort: true
        }, {
            dataField: 'downloads',
            text: 'Downloads',
            formatter: this.downloadCellFormatter,
            sort: true
        }, {
            dataField: 'isApproved',
            text: 'Status',
            formatter: this.statusCellFormatter,
            sort: true
        }, {
            dataField: 'View',
            text: 'View',
            formatter: this.viewCellFormatter,
        }, {
            dataField: 'Delete',
            text: 'Delete',
            formatter: this.deleteActionCellFormatter,
        }];
        return (<React.Fragment>
            <BootstrapTable defaultSortDirection="asc" bootstrap4 keyField='index' data={filterList} columns={columns} />
        </React.Fragment>)
    }

    indexCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{rowIndex + 1}</span>
    }

    vNameCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{`${row.vendorName ? row.vendorName : '-'}`}</span>
    }

    emailCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{`${row.vendorEmail ? row.vendorEmail : '-'}`}</span>
    }

    dateCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{moment(row.createdOn).format("YYYY-MM-DD")}</span>
    }

    tradingCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{row.tradingName ? row.tradingName : '-'}</span>
    }

    categoryCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{row.categoryName ? row.categoryName : '-'}</span>
    }

    downloadCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{`${row.downloads ? row.downloads : 0}`}</span>
    }

    statusCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <span>{`${row.isApproved ? 'Live' : 'Pending'}`}</span>
    }

    viewCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        const url = CONSTANT.url.catalogueDetail.replace(':id', row.id);
        const appUrl = CONSTANT.url.catalogueApproval.replace(':id', row.id);
        return <a href={row.isApproved ? url : appUrl}><span className="btn kt-font-brand">View Details</span></a>
    }

    deleteActionCellFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
            <span className="btn kt-font-danger" onClick={() => this.deleteCatalogue(row)}><i className="fa fa-trash-alt"></i></span>
        )
    }

    onChangePlan = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    loadList = () => {
        const { page, limit, sort } = this.state;

        this.setState({ showLoader: true });
        getCatalogue().then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    list: res.data,
                    filterList: res.data,
                    count: 0,
                });
            }
        }).catch((error: any) => {
            this.setState({
                showLoader: false,
                list: [],
                count: 0,
            });
        });
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }

    search = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);

        const filterSearch = this.filterIt(this.state.list, value);
        this.setState({ filterList: filterSearch });
    }

    filterIt = (arr: any, searchKey: string) => {
        return arr.filter((obj: any) => {
            return Object.keys(obj).some((key) => {
                if (obj[key] !== null) {
                    const tempKey = obj[key].toString().toLowerCase();
                    const tempSearch = searchKey.toLowerCase();
                    return tempKey.includes(tempSearch);
                }
            });
        });
    }

    deleteCatalogue = (detail: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete catalogue ${detail.title}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                deleteCatalogue({ catalogueId: detail.id, categoryId: detail.categoryId }).then((res: any) => {
                    this.setState({ showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Catalogue has been deleted.',
                        'success'
                    )
                    this.loadList();
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your catalogue is safe :)',
                    'error'
                )
            }
        })
    }

}

export default CatalogueList;
