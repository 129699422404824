import * as React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import CONSTANT from './../../constant';
import { getUserWithPlanId } from './../../action/PlanAction';
import { onChange } from './../../utils';

class UserPlanList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const parsed: any = queryString.parse(props.location.search);
        this.state = {
            planId: props.match.params.id,
            list: undefined,
            filteredList: undefined,
            page: 1,
            limit: 10,
            sort: 1,
            count: 0,
            showLoader: false,
            keyword: { name: 'keyword', value: '', error: '', isRequired: false },
            planType: { name: 'planType', value: 'all', error: '', isRequired: false },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Users with Plan Name
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { keyword, planType, filteredList } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">

                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <div className="form-group row text-center pt-3">
                                        <div className="radio-inline">
                                            <label className="radio radio-primary">
                                                <input type="radio" name={planType.name} className="mr-2" value="all" checked={planType.value === 'all'} onChange={this.onChangePlan} />
                                                <span>All</span>
                                            </label>
                                            <label className="radio radio-primary ml-5">
                                                <input type="radio" name={planType.name} className="mr-2" value="active" checked={planType.value === 'active'} onChange={this.onChangePlan} />
                                                <span>Active</span>
                                            </label>
                                            <label className="radio radio-primary ml-5">
                                                <input type="radio" name={planType.name} className="mr-2" value="inactive" checked={planType.value === 'inactive'} onChange={this.onChangePlan} />
                                                <span>Inactive</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-input-icon kt-input-icon--right kt-subheader__search" style={{ width: 300 }}>
                                        <input name={keyword.name} type="text" className="form-control" placeholder="Search" value={keyword.value} onChange={this.searchUser} />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '8%' }}>No.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Plan</th>
                                                    <th>Days Left</th>
                                                    <th>Plan Status</th>
                                                    <th>View</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredList === undefined && <tr>
                                                        <td colSpan={10} className="text-center">Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    filteredList !== undefined && filteredList.length === 0 && <tr>
                                                        <td colSpan={10} className="text-center">No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    filteredList !== undefined && filteredList.map((item: any, index: number) => {
                                                        const url = CONSTANT.url.userDetail.replace(':id', item.createdBy);
                                                        let currentDate = moment();
                                                        let prevDate = moment.unix(item.endDate);
                                                        const diff = prevDate.diff(currentDate, 'days')
                                                        return (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.userName ? item.userName : '-'}</td>
                                                                    <td>{`${item.userEmail ? item.userEmail : '-'}`}</td>
                                                                    <td>{`${item.userPhone ? item.userPhone : '-'}`}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{diff > 0 ? diff : 0}</td>
                                                                    <td>{`${item.isActive ? 'Active' : 'Inactive'}`}</td>
                                                                    <td>
                                                                        <a href={url}><span className="btn kt-font-brand">View Details</span></a>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        )
    }

    private onChangePlan = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        
        if (value === 'all') {
            this.setState({ filteredList: this.state.list });
        }
        if (value === 'active') {
            const filter = this.state.list.filter((i: any) => i.isActive);
            this.setState({ filteredList: filter });
        }
        if (value === 'inactive') {
            const filter = this.state.list.filter((i: any) => i.isActive === false);
            this.setState({ filteredList: filter });
        }
        onChange(this, name, value);
    }

    loadList = () => {
        const { planId, page, limit, sort } = this.state;
        getUserWithPlanId(planId, page, limit, sort).then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    list: res.data.list,
                    count: 0,
                    filteredList: res.data.list,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, filteredList: [], count: 0 });
        });
    }

    searchUser = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);

        const filterSearch = this.filterIt(this.state.list, value);
        this.setState({ filteredList: filterSearch });
    }

    filterIt = (arr: any, searchKey: string) => {
        return arr.filter((obj: any) => {
            return Object.keys(obj).some((key) => {
                if (obj[key] !== null) {
                    const tempKey = obj[key].toString().toLowerCase();
                    const tempSearch = searchKey.toLowerCase();
                    return tempKey.includes(tempSearch);
                }
            });
        });
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }

}

export default UserPlanList;
