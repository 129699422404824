import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import { addSupport, getSupport } from '../../action/ContentAction';
import { onChange } from './../../utils';

class SupportUS extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            id: undefined,
            email: { name: 'email', value: '', error: '', isRequired: true },
            phone: { name: 'phone', value: '', error: '', isRequired: true },
            address: { name: 'address', value: '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Content management
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                Support
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { email, phone, address } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Support
                                </h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">

                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Contact email id"
                                    name={email.name}
                                    value={email.value}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    className={phone.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Contact phone"
                                    name={phone.name}
                                    value={phone.value}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    className={address.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Contact address"
                                    name={address.name}
                                    value={address.value}
                                    onChange={this.onChange}
                                />
                            </div>
                            
                            <div className="row mt-3">
                                <div className="col-md-2 pl-4"></div>
                                <div className="col-md-2"></div>
                                <div className="col-md-2">
                                    <button type="button" className="btn btn-sm btn-primary btn-block" onClick={this.onPublish}>Publish</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onSaveDraft = (model: any) => {
        
    }

    loadList = () => {
        this.setState({ showLoader: true });
        getSupport().then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({
                    email: { ...this.state.email, value: res.data.content.email },
                    phone: { ...this.state.phone, value: res.data.content.phone },
                    address: { ...this.state.address, value: res.data.content.address },
                    id: res.data.id,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onPublish = () => {
        this.setState({ showLoader: true });
        let reqObj: any = {
            email: this.state.email.value,
            phone: this.state.phone.value,
            address: this.state.address.value
        }
        if (this.state.id) {
            reqObj.id = this.state.id
        }
        addSupport(reqObj).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 201) {
                toast.success("Success");
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default SupportUS;
