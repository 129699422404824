import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const createCoupon = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getVendorCoupon = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/admin?t=vendor`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getUserCoupon = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/admin?t=user`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const getCouponDetail = (couponId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/vendor/${couponId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const updateCouponDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/detail`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}

export const deleteCoupon = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/delete`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        console.log(err.response);
        throw err.response;
    });
}