import * as React from 'react';
import { getBreedList, getBreedWithAnimal } from './../../action/CategoryAction';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const id = props.animalTypeId;
        this.state = {
            animalTypeId: props.animalTypeId,
            isCheckedAll: false,
            mapData: undefined,
            breedList: undefined,
            existingBreed: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { mapData, isCheckedAll } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row text-center py-2 text-center">
                    <div className="col-lg-3 form-group">
                        <div className="checkbox-inline">
                            <label className="checkbox checkbox-lg">
                                <input type="checkbox" name="all" value="all" checked={isCheckedAll} onChange={this.onChangeAll} />
                                <span className="ml-2">All</span>
                            </label>
                        </div>
                    </div>

                    {mapData && mapData.map((item: any, index: any) => {
                        return (
                            <div className="col-lg-3 form-group">
                                <div className="checkbox-inline">
                                    <label className="checkbox checkbox-lg">
                                        <input type="checkbox" name={item.breed} value={item.id} onChange={this.onChangeCheckbox} checked={item.isChecked} />
                                        <span className="ml-2">{item.breed}</span>
                                    </label>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <div className="row">
                    <div className="col-md-3">
                        <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                    </div>
                    <div className="col-md-3" />
                    <div className="col-md-3" />
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary btn-sm btn-sm btn-block" onClick={this.onSubmit}>Save Changes</button>
                    </div>
                </div>
            </form>
        )
    }

    loadList = () => {
        const { mapData, animalTypeId } = this.state;
        this.setState({ showLoader: true });
        getBreedList().then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    breedList: res.data,
                });
                getBreedWithAnimal(animalTypeId).then((response: any) => {
                    if (res.status === 200) {
                        const breedList = response.data.length > 0 ? response.data[0].breeds : [];
                        const filterD = res.data.map((item: any) => {
                            item.isChecked = false;
                            for (let i = 0; i < breedList.length; i++) {
                                if (breedList[i].breed === item.breed) {
                                    item.isChecked = true;
                                }
                            }
                            return item;
                        });

                        this.setState({
                            showLoader: false,
                            mapData: filterD,
                            existingBreed: response.data
                        });
                    }
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });


    }

    private onChangeAll = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;

        const { mapData } = this.state;

        const filterAllow = mapData.map((i: any) => {
            i.isChecked = checked;
            return i;
        });
        this.setState({ mapData: filterAllow, isCheckedAll: checked });
    }

    private onChangeCheckbox = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;

        const { mapData } = this.state;

        const filterAllow = mapData.map((i: any) => {
            if (value === i.id) {
                i.isChecked = checked;
            }
            return i;
        });
        this.setState({ mapData: filterAllow, isCheckedAll: false }, () => {
            let totalLength = mapData.length;
            let checkedLength = mapData.filter((i: any) => i.isChecked).length;
            if (totalLength === checkedLength) {
                this.setState({ isCheckedAll: true });
            }
        });
    }

    onSubmit = (e: any) => {
        const { mapData, existingBreed } = this.state;

        const filterBreed = mapData.filter((i: any) => i.isChecked).map((i: any) => {
            let obj = { breed: i.breed, id: i.id }
            return obj;
        });
        if (filterBreed.length > 0) {
            let isEdit = false;
            const model = {
                animalTypeId: this.state.animalTypeId,
                breeds: filterBreed
            }
            if (existingBreed.length > 0) {
                isEdit = true
            }
            this.props.onSubmit(model, isEdit);
        }
    }

}

export default AddComponent;