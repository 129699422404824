import * as React from 'react';
import moment from 'moment';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import CONSTANT from './../../constant';
import Shimmer from '../../component/common/Shimmer';
import { getUserManagementDetail, getUserSubscribedPlans } from './../../action/UserAction';
import { getActivityLog } from './../../action/ActivityAction';
import { getDateParts } from '../../utils';
const defaultProfile = require("./../../assets/media/users/default.jpg")

class VendorDetail extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            list: undefined,
            activities: undefined,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { detail, activities } = this.state;
        return (
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <Sidebar />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {this.renderHeader()}
                            {detail && activities ? this.renderBody() : <Shimmer />}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            User Management
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {detail && detail.displayName}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { detail, list, activities } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="card card-custom gutter-b card-stretch">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="row">

                                            <div className="col-lg-4">
                                                <div className="mt-3">
                                                    <div className="example-preview" id="kt_blockui_content">
                                                        {detail && detail.photoURL ?
                                                            <img src={detail.photoURL} className="img-fluid" alt="image" height="200" width="200" />
                                                            : <img src={defaultProfile} className="img-fluid" alt="image" height="200" width="200" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-8">
                                                <div className="row mt-3">
                                                    <label className="col-12 col-form-label font-weight-bold">
                                                        <h3 className="mb-3">{detail ? detail.displayName : '-'}</h3>
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Trading Name</label>
                                                    <label className="col-8 col-form-label">{detail && detail.tradingName ? detail.tradingName : '-'}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Phone no.</label>
                                                    <label className="col-8 col-form-label">{detail && detail.phone ? detail.phone : '-'}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Email</label>
                                                    <label className="col-8 col-form-label">{detail && detail.email ? detail.email : '-'}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Address</label>
                                                    <label className="col-8 col-form-label">{detail && detail.address ? detail.address : '-'}</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4">
                                        <div className="row">
                                            <div className="d-flex align-items-end mb-7">
                                                <div className="d-flex">
                                                    <div className="d-flex flex-column ml-5">
                                                        <h2 className="mb-4">Activity Log</h2>
                                                        <div className="d-flex flex-column p-2 border border-dark">
                                                            {activities && activities.map((item: any) => {
                                                                const creOn = getDateParts(item.data.createdOn);
                                                                return (
                                                                    <span className="text-muted font-weight-bold mb-2">{`- ${item.data.activityName} ${creOn.date}/${creOn.month}/${creOn.year}`}</span>
                                                                )
                                                            })}
                                                            {activities && activities.length === 0 &&
                                                                <span className="text-muted font-weight-bold mb-2">No Activity</span>
                                                            }
                                                        </div>
                                                        {detail.deviceName &&
                                                            <div className="d-flex flex-row mt-2">
                                                                <span className="text-muted font-weight-bold">Device</span>
                                                                <span className="text-muted ml-3">{`${detail.deviceName}`}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">

                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Plans
                                    </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '8%' }}>No.</th>
                                                    <th>Plan Name</th>
                                                    <th>Feature</th>
                                                    <th>Cost</th>
                                                    <th>Validity</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list === undefined && <tr>
                                                        <td colSpan={10} className="text-center">Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.length === 0 && <tr>
                                                        <td colSpan={10} className="text-center">No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.map((item: any, index: number) => {
                                                        const url = CONSTANT.url.userDetail.replace(':id', item.id);
                                                        const startDate = moment(item.startDate * 1000);
                                                        const endDate = moment(item.endDate * 1000);
                                                        return (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{`${item.features}`}</td>
                                                                    <td>{`$${item.price}`}</td>
                                                                    <td>{`${startDate.format("MMM DD, YYYY")} - ${endDate.format("MMM DD, YYYY")}`}</td>
                                                                    <td>{item.endDate > new Date().getTime() ? 'Live' : 'Expired'}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { id } = this.state;
        const model = { uid: id };
        getUserManagementDetail(model).then((res: any) => {
            this.setState({
                detail: res.data
            });
        }).catch((error: any) => {
            console.log(error)
        });

        getUserSubscribedPlans(id).then((res: any) => {
            this.setState({
                list: res.data
            });
        }).catch((error: any) => {
            console.log(error)
        });

        getActivityLog(id).then((res: any) => {
            this.setState({
                activities: res.data
            });
        }).catch((error: any) => {
            console.log(error)
        });
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }
}

export default VendorDetail;