import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import { addFaq, getFaq } from '../../action/ContentAction';
import { onChange } from './../../utils';

class Faq extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            id: undefined,
            question: { name: 'question', value: '', error: '', isRequired: true },
            answer: { name: 'answer', value: '', error: '', isRequired: true },
            faqList: undefined,
            showAddField: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Content management
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                FAQ
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { question, answer, showAddField, faqList } = this.state;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    FAQ
                                </h3>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <button className="btn btn-brand btn-elevate btn-icon-sm" onClick={this.onAddNew}>
                                    Add New
                                </button>
                            </div>
                        </div>

                        <div className="kt-portlet__body">

                            {faqList && faqList.map((item: any) => {
                                const url = CONSTANT.url.editFaq.replace(':id', item.id);
                                return (
                                    <div className="p-4 mb-2 border">
                                        <div className="row col-12">
                                            <div className="col-1">
                                                <i className="fa fa-circle mr-5"></i>
                                            </div>
                                            <div className="col-10 ">
                                                <label className="font-weight-bold">
                                                    <h5>{item.content.question}</h5>
                                                </label>
                                            </div>
                                            <div className="col-1">
                                                <button className="btn btn-sm pull-right" >
                                                    <a href={url}><i className="fa fa-edit"></i>{item.name}</a>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row col-12">
                                            <div className="col-1" />
                                            <div className="col-10">
                                                <span className="text-muted font-weight-bold">{item.content.answer}</span>
                                            </div>
                                            <div className="col-1" />
                                        </div>

                                    </div>
                                )
                            })}

                        </div>
                    </div>

                </div>

                {showAddField &&
                    <div className="row">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                <div className="mt-3">
                                    <div className="row">
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="text"
                                                className={question.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Write question here"
                                                name={question.name}
                                                value={question.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 form-group">
                                            <textarea
                                                className={answer.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                rows={5}
                                                placeholder="Write answer here"
                                                name={answer.name}
                                                value={answer.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-2 pr-4">
                                            <button type="button" className="btn btn-sm btn-primary btn-block" onClick={this.onPublish}>Publish</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                }

            </div>
        )
    }

    onAddNew = () => {
        this.setState({ showAddField: true })
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onSaveDraft = (model: any) => {
        
    }

    loadList = () => {
        this.setState({ showLoader: true });
        getFaq().then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    faqList: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({
                showLoader: false,
            });
        });
    }

    onPublish = () => {
        this.setState({ showLoader: true });
        let reqObj: any = {
            question: this.state.question.value,
            answer: this.state.answer.value,
        }
        if (this.state.id) {
            reqObj.id = this.state.id
        }
        addFaq(reqObj).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 201) {
                toast.success("Success");
                this.loadList();
                this.setState({ question: '', answer: '', showAddField: false })
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default Faq;